import "../css/style.css";

import React, { useState, useEffect } from "react";
import {
    API_GET_DropDown,
    API_POST_CustSerGet,
    API_POST_ReviewGet,
    API_PUT_CustSer_ReviewSave,
    API_POST_VideoTeaGet,
    API_PUT_ReserveSave,
    API_GET_Score_GetOrderList
} from "../global/constants.js";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件

function Review() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");

    const [DropDown, setDropDown] = useState([{}]);
    const [onlineState, setonlineState] = useState("");
    const [dataTable, setDataTable] = useState([{}]);
    const [OrderNo, setOrderNo] = useState(""); //收款帳號
    const [SDate, setSDate] = useState(""); //收款日期起日
    const [EDate, setEDate] = useState(""); //收款日期迄日
    const [reviewSingle, setReviewSingle] = useState([{}]);//審核單筆容器
    const [teaGet, setTeaGet] = useState({});//GoogleMeeting查詢結果容器
    const [teaOrder, setTeaOrder] = useState('');//GoogleMeeting選定案件
    const [teaGetEdit, setTeaGetEdit] = useState(false);//GoogleMeeting可否編輯

    const [orderNum, setOrderNum] = useState("");
    const [orderFList, setOrderFList] = useState([]);
    const [orderOList, setOrderOList] = useState([]);

    function getData() {
        //狀態下拉選單
        API_GET_DropDown()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    //console.log(data);
                    setDropDown(data.result.onlineStateArray);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    useEffect(() => {
        //檢查是否登入

        getData();
        getTable(0);
    }, []);

    function getTable(setP) {
        API_POST_CustSerGet({
            OrderNo: OrderNo,
            SDate: SDate,
            EDate: EDate,
            OnlineState: onlineState
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    console.log(data.result);
                    setDataTable(data.result);
                    setTableLength(data.result.length);
                    if (!setP) { setCurPage(0); }
                    else { setCurPage(setP); }
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function hideModal() {
        $(".modal").modal('hide');
    }

    function showOrder(ordNum) {
        API_GET_Score_GetOrderList({
            OrderNo: ordNum
        }).then((response) => response.data)
            .then((data) => {
                let setYet = false;
                console.log(data);
                if (data.code == "0000") {
                    if (Object.keys(data.result).length > 0) {
                        if (data.result.fileListInfo.length > 0) {
                            setOrderFList(data.result.fileListInfo);
                        }
                        if (data.result.oderListInfo && data.result.oderListInfo.length > 0) {
                            setOrderOList(data.result.oderListInfo[0]);
                            setYet = true;
                        }

                    }
                }
                if (setYet) {
                    $("#sys_orderView").modal('show');
                } else {
                    if (data.code == "0000") {
                        alert("伺服器查無資料 或 網路不穩無法加載圖片");
                    } else {
                        alert(data.message);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //審核視窗顯示
    function reviewFile(order) {
        $('[name="aduitRes"]').each(function (index, order) {
            $(this).prop("checked", false)
        });
        let existOrder = "";
        //檢查是否有該訂單
        dataTable.forEach((value, index) => {
            if (value.OrderNo == order) {
                existOrder = value.OrderOID;
                //載入訂單資料
                setReviewSingle({
                    'OrderNo': value.OrderNo,
                    'OrderOID': value.OrderOID,
                    'OnlineName': value.OnlineName,
                    'OrderSVState': "",
                    'OrderSVReason': "",
                    "OnlinePaperNo": "",
                })
            }
        })
        if (!existOrder) return alert('查無訂單');

        API_POST_ReviewGet({
            OrderOID: existOrder,
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    console.log(data);

                    setReviewSingle(prevState => ({
                        ...prevState,
                        OnlinePaperNo: data.result[0].OnlinePaperNo
                    }));
                    console.log(data.result[0])
                    $("#sys_review").modal('show');
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //審核結果輸入到暫存容器
    function reviewRes(e, vName) {
        setReviewSingle(function (prev) {
            let newReviewSingle = { ...prev };
            switch (vName) {
                case "OrderSVState":
                    newReviewSingle.OrderSVState = String(e.target.value);
                    if (e.target.value == 1) {
                        newReviewSingle.OrderSVReason = "";
                    }
                    break;
                case "OrderSVReason":
                    if (newReviewSingle.OrderSVState == 4)
                        newReviewSingle.OrderSVReason = e.target.value;
                    break;
            }
            return newReviewSingle;
        });
    }
    //審核結果送出
    function reviewPut() {
        console.log(reviewSingle)
        if (reviewSingle.OrderOID && reviewSingle.OrderSVState) {
            if (reviewSingle.OrderSVState == 4 && reviewSingle.OrderSVReason == "") {
                return alert('審核不通過理由必填');
            }
            API_PUT_CustSer_ReviewSave({
                "OrderOID": reviewSingle.OrderOID,
                "OnlinePaperNo": reviewSingle.OrderPaperNo,
                "OrderSVState": reviewSingle.OrderSVState,
                "OrderSVReason": reviewSingle.OrderSVReason,
            })
                .then((response) => response.data)
                .then((data) => {
                    if (data.code == "0000") {
                        hideModal();
                        getTable(curPage);
                    }
                    else {
                        alert(data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            alert('請選擇案件審核結果')
        }
    }

    //顯示已預約GoogleMeeting
    function signMeeting(order, editAble) {
        //取得api 代入視訊時間是否安排
        API_POST_VideoTeaGet({
            "OrderOID": order//--閱卷流水號 測試oid=2
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    //預約
                    console.log(data.result[0])
                    setTeaGet(data.result[0]);
                    if (editAble) {
                        $("#teaGetEdit").removeAttr("readonly");
                    } else {
                        $("#teaGetEdit").attr("readonly", "readonly");
                    }
                    setTeaGetEdit(editAble);
                    setTeaOrder(data.result[0].ClassRID)
                    $("#sys_booking").modal('show');
                } else if (data.code == "4003") {
                    //尚未預約;
                    alert(`訂單編號：${order}請洽客服人員進行預約設定`);
                } else {
                    throw new Error("伺服器拒絕查詢");
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //編輯GoogleMeetingRoom
    function editMeetingRoom(e) {
        //teaGetEdit
        if (e.target.value && teaGetEdit && e.target.id == "teaGetEdit") {
            setTeaGet
            setReviewSingle(function (prev) {
                let newTeaGet = { ...prev };
                newTeaGet.ClassMeetUrl = e.target.value;
                return newTeaGet;
            });
        }
    }
    //更新GoogleMeeting
    function updateMeetRoom() {
        if (!teaOrder || !newTeaGet.ClassMeetUrl) { return alert('連線失敗，無法更新Google Meeting教室') }

        API_PUT_ReserveSave({
            ClassRID: teaOrder,
            ClassMeetUrl: newTeaGet.ClassMeetUrl,
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    console.log(data);
                    hideModal();
                    getTable(curPage);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //換頁 Start 資料容器
    const [eachPage, setEachPage] = useState(10);//每頁筆
    const [curPage, setCurPage] = useState(0);//當前頁面
    const [tableLength, setTableLength] = useState(0);//全部資料容器
    //setTableLength(data.result.length);setCurPage(0); 動作: 取得新資料 並 回到第一頁
    //呼叫 <PageNav alLength={資料長度} eachPage={每頁筆數} />
    //表格增加if (i < curPage * eachPage || i > (curPage + 1) * eachPage-1) { return }
    function PageNav(prop) {

        var history = useHistory();


        const [totalPage, setTotalPage] = useState(1);
        const [eachPage, setEachPage] = useState(10);

        useEffect(() => {
            let al = parseInt(prop.alLength) || 0,
                eP = parseInt(prop.eachPage) || 10;
            //console.log(prop)
            //console.log(al)
            setEachPage(eP);

            if (Math.ceil(al / eP)) {
                setTotalPage(Math.ceil(al / eP));
            } else {
                setTotalPage(1);
            }

        }, [prop]);

        //換頁
        function pageDirct(e) {
            let forward = e.target.dataset.pagemove, calPage = curPage;
            if (forward == "last") { calPage--; }
            else if (forward == "next") { calPage++; }
            else {
                calPage = parseInt(forward) || 0;
            }
            if (calPage <= 0) { calPage = 0 }
            else if (calPage >= totalPage - 1) { calPage = totalPage - 1; }


            setCurPage(calPage);
        }

        return (
            <div id="swichPageNav" className="row justify-content-center" data-curpage={curPage}>
                <div className="col-auto">
                    <a className="link-primary mx-2" data-pagemove="last" onClick={pageDirct}>上一頁</a>
                </div>
                <div className="col-auto">
                    {(curPage > 5 ?
                        <a className="link-primary mx-2" data-pagemove={(curPage - 5)} onClick={pageDirct} >...</a >
                        : "")}
                    {Array.from({ length: totalPage }, (_, index) => {
                        if (index < curPage - 5 || index > curPage + 5) {
                            return null;
                        }
                        return (
                            <a key={index} className="link-primary px-1 mx-1" data-pagemove={index} onClick={pageDirct} >
                                {(index == curPage ? `[${(index + 1)}]` : index + 1)}
                            </a>
                        )
                    })}
                    {(curPage + 5 < totalPage ?
                        <a className="link-primary mx-2" data-pagemove={(curPage + 5)} onClick={pageDirct} >...</a >
                        : "")}
                </div>
                <div className="col-auto">
                    <a className="link-primary mx-2" data-pagemove="next" onClick={pageDirct} >下一頁</a >
                </div>
            </div>
        );
    }
    //換頁End

    //測試用function
    function consoleFuc() {
    }

    return (
        <>
            <div className="top"></div>
            <div id="main" className=""
                style={{ fontSize: "1.2rem", width: "100%", height: "100vh", position: "relative", backgroundColor: "#fff", overflowX: "hidden", overflowY: "scroll" }} >
                {/*backgroundImage: "url(images/service/service-bg.jpg)", backgroundPosition: "center center", backgroundSize: "cover"*/}
                <div style={{ height: "100px" }} >{/*blank for header*/}</div>
                <div className="row px-lg-5 px-2">

                    {/*搜尋列*/}<div className="col-12 pb-2">
                        <div className="row">
                            <div className="col-auto py-2">
                                {/*<button type="button" onClick={consoleFuc} >測試</button>*/}
                                <span>訂單日期：</span>
                                <input type="date" placeholder="2022/01/02" className="datepicker" value={SDate} onChange={(e) => { setSDate(e.target.value) }} />
                                <span className="px-3">~</span>
                                <input type="date" placeholder="2022/01/02" className="datepickerTW" value={EDate} onChange={(e) => { setEDate(e.target.value) }} />
                            </div>
                            <div className="col-auto py-2">
                                <span htmlFor="orderNo">訂單編號：</span>
                                <input id="orderNo" type="text" placeholder="xxxxxxxxx" value={OrderNo} onChange={(e) => { setOrderNo(e.target.value) }} />
                            </div>
                            <div className="col-auto py-2">
                                <span htmlFor="assignStatus">狀態：</span>
                                <select id="assignStatus" defaultValue="" value={onlineState} onChange={(e) => { setonlineState(e.target.value) }}>
                                    <option value="">-全部-</option>
                                    {DropDown.map((y, key) => (
                                        <option key={key} value={y.id}>{y.name} </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-auto py-2">
                                <button type="button" className="btn btn-primary" onClick={getTable}>搜尋</button>
                            </div>
                        </div>
                    </div>
                    {/*主要內容*/}<div className="col-12" style={{ minHeight: `calc(${eachPage} * 56px)` }} >
                        <table className="table table-striped border-bottom">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-primary">訂單時間-日期</th>
                                    <th scope="col" className="text-primary">訂單編號</th>
                                    <th scope="col" className="text-primary">方案名稱</th>
                                    <th scope="col" className="text-primary">試卷審核</th>
                                    <th scope="col" className="text-primary">批閱狀態</th>
                                    <th scope="col" className="text-primary">批改老師</th>
                                    <th scope="col" className="text-primary">批改結果</th>
                                    <th scope="col" className="text-primary">視訊教學日期時間</th>
                                    <th scope="col" className="text-primary">視訊教學</th>
                                </tr>
                            </thead>
                            <tbody className="table-hover">
                                {dataTable.map((x, i) => {
                                    if (!x) { return null }
                                    if (i < curPage * eachPage || i > (curPage + 1) * eachPage - 1) { return }
                                    return (
                                        <tr key={i} >
                                            {/*訂單時間*/}
                                            <td className="align-middle col-1">{x.OrderDate}</td>
                                            {/*訂單編號*/}
                                            <td className="align-middle col-1">
                                                <a className="link-primary" onClick={(e) => showOrder(x.OrderNo)} >{x.OrderNo}</a>
                                            </td>
                                            {/*方案名稱*/}
                                            <td className="align-middle col-1">{x.OnlineName}</td>
                                            {/*試卷審核*/}
                                            <td className="align-middle col-1">
                                                {(x.SVState == "待審核"
                                                    ?
                                                    <button className="btn btn-primary" onClick={(e) => reviewFile(x.OrderNo)}>審核</button>
                                                    :
                                                    x.SVState
                                                )}
                                            </td>
                                            {/*批閱狀態*/}
                                            <td className="align-middle col-1">{x.State}</td>
                                            {/*批改老師*/}
                                            <td className="align-middle col-1">{x.TeacherName}</td>
                                            {/*批改結果*/}
                                            <td className="align-middle col-1">{x.SCPath}
                                                {(x.SCPath != null
                                                    ?
                                                    <a className="link-primary" href={x.SCPath} target="blank">x.SCCaption</a>
                                                    :
                                                    ''
                                                )}
                                            </td>
                                            {/*視訊教學日期時間*/}
                                            <td className="align-middle col-1" scope="col">
                                                {/*{(x.OnlineNo == "C" ? x.ClassRDate : "")}*/}
                                                {(x.OnlineNo != "" ? x.ClassRDate : "")}
                                            </td>
                                            {/*視訊教學*/}
                                            <td className="align-middle col-1">
                                                {(x.Videoteach == "預約"
                                                    ?
                                                    <button className="btn btn-primary" onClick={(e) => signMeeting(x.OrderNo, true)}>預約</button>
                                                    :
                                                    (x.Videoteach == "查看"
                                                        ?
                                                        <button className="btn btn-dark" onClick={(e) => signMeeting(x.OrderNo, false)}>查看</button>
                                                        :
                                                        (x.Videoteach == ""
                                                            ?
                                                            ''
                                                            :
                                                            <a className="link-primary" href={x.Videoteach} target="blank">x.Videoteach</a>
                                                        )
                                                    )
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>

                    </div>
                    {/*換頁*/}<div className="col-12 py-3" style={{ backgroundColor: "#f2f2f2" }} >
                        <PageNav alLength={tableLength} eachPage={eachPage} />
                    </div>
                </div>

                <div className="footer sticky-bottom pt-5">
                    <div className="row text-center justify-content-center">
                        <div className="col-12">
                            <p>COPYRIGHT@<img src="images/EZwriting-txt.png" />　407臺中市西屯區長安路一段83號5樓<br />
                                電話：(04)2315-2500　客服信箱：service@ezwritingbar.com.tw</p>
                        </div>
                    </div>
                </div>
            </div>

            {/*<!--訂單詳情-->*/}
            <div className="modal fade" id="sys_orderView" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "650px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-10">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>訂單編號：{orderOList.OrderNo || ""}</td>
                                            </tr>
                                            <tr>
                                                <td>方案：{orderOList.OnlineName || ""}</td>
                                            </tr>
                                            <tr>
                                                <td>作文題目：{orderOList.OrderQun || ""}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    題目圖檔：<br />
                                                    {
                                                        orderOList && Object.keys(orderOList).length > 0 && orderOList.ATTFPath ?
                                                            <img src={"/" + orderOList.ATTFPath} alt="題目圖檔.jpg" style={{ maxHeight: "200px", width: "auto", maxWidth: "100%" }} />
                                                            :
                                                            "(無)"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    作文圖檔：<br />
                                                    {
                                                        orderFList.map((x, i) =>
                                                            <img key={i} src={"/" + x.ATTFPath} alt={"作文圖檔" + (i + 1)} style={{ maxHeight: "200px", width: "auto", maxWidth: "100%" }} />
                                                        )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-2">
                                    <button type="button" className="btn btn-outline-primary" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--訂單詳情 END-->*/}

            {/*<!--文件初次審核-->*/}
            <div className="modal fade" id="sys_review" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "650px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-11">
                                    <h3 className="text-center">文件審核</h3>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td colSpan="4">訂單編號： {reviewSingle.OrderNo}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">方案：{reviewSingle.OnlineName}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingLeft: "32px" }} >
                                                    <span>
                                                        <label htmlFor="aduitAprove">
                                                            <input className="form-check-input rounded-circle" type="radio" id="aduitAprove" name="aduitRes" value="1" onChange={(e) => reviewRes(e, 'OrderSVState')} />通過
                                                        </label>
                                                    </span><br />
                                                    <span>
                                                        <label htmlFor="aduitRejectB" className="pr-4"><input className="form-check-input rounded-circle" type="radio" id="aduitRejectB" name="aduitRes" value="4" onChange={(e) => reviewRes(e, 'OrderSVState')} />不通過</label><input type="text" value={reviewSingle.OrderSVReason} onChange={(e) => reviewRes(e, 'OrderSVReason')} ></input>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-4">
                                    <button type="button" className="btn btn-primary mx-4" onClick={reviewPut} >送出</button>
                                    <button type="button" className="btn btn-outline-primary mx-4" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--文件初次審核 END-->*/}

            {/*<!--一對一線上教學預約-->*/}
            <div className="modal fade" id="sys_booking" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "850px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-11">
                                    <h3 className="text-center">一對一線上教學預約</h3>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td colSpan="4">訂單編號： {teaGet.OrderNo}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">預約日期： {String(teaGet.ClassRDate).split('T')[0]}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">方案：{teaGet.OnlineName}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">批改老師帳號：{teaGet.TeacherOnlineAcnt}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">批改老師姓名：{teaGet.TeacherName}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">批改老師Google帳號：{teaGet.TeacherGMail}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">Google帳號密碼：{teaGet.TeacherGPwd}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">
                                                    Google Meting 網址：<br />
                                                    <input id="teaGetEdit" className={(teaGetEdit ? "form-control my-2" : "form-control my-2 border-0 user-select-all")} type="text" value={teaGet.ClassMeetUrl} onChange={editMeetingRoom} readOnly />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-4">
                                    <button type="button" className={(teaGetEdit ? "btn btn-primary mx-4" : "d-none")} onClick={updateMeetRoom} >確定</button>
                                    <button type="button" className="btn btn-outline-primary mx-4" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--一對一線上教學預約 END-->*/}

        </>
    );
}


export default Review;