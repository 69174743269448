import axios from 'axios';
import { getAuthToken } from './TokenUtil';

export const getHeaders = (token) => {
    return {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': token
    }
}

//API URL
export const API_HOST = window.apiUrl;
export const correct_API_HOST = window.apiCorrectUrl;

//�@�뱡�p��
const baseRequest = axios.create({
    baseURL: API_HOST
});

const correctRequest = axios.create({
    baseURL: correct_API_HOST
});
//�U����
export const downloadRequest = axios.create({
    baseURL: API_HOST,
    responseType: 'blob'
});

//�]��
export const API_CHOST = "https://www.focas.fisc.com.tw/FOCAS_WEBPOS";

const baseCRequest = axios.create({
    baseURL: API_CHOST
});


//#region �@��api
export const apiLangSetting = data => baseRequest.get('/languageData');
export const apiAreaSetting = data => baseRequest.get('/areaData');
//#endregion

//#region �D�e��
//�D�e��-���o�v��
//export const API_GET_HomeData = data => baseRequest.get('/home/data', { headers: getHeaders(getAuthToken()) });
//#endregion

//#region �\��-0
//0-0 ���ͨϥΪ̱b��
//export const API_Account_Create = data => baseRequest.put('/account/createuser', JSON.stringify(data), { headers: getHeaders(getAuthToken()) }); 
//0-3 ���m�K�X
//export const API_Account_ResetPWD = data => baseRequest.get('/authenticate/resetpwd', { params: data, headers: getHeaders(getAuthToken()) });
//0-4 �C�X�ϥΪ��v��-------------------- 
//0-5 �ϥΪ̵��U
export const API_SignUp = data => baseRequest.post('/Account/CreateAccount', JSON.stringify(data), { data: data, headers: getHeaders() });
//0-5 �e�x �ϥΪ̵n�J
export const API_SignIn = data => baseRequest.post('/Login/Login', JSON.stringify(data), { data: data, headers: getHeaders() });
//0-5 �e�x �ϥΪ̵n�J
export const API_OAuth = data => baseRequest.post('/Login/OAuthLogin', JSON.stringify(data), { data: data, headers: getHeaders() });

//0-1 ��x �n�J
export const API_Login_BackLogin = data => baseRequest.post('/Login/BackLogin', JSON.stringify(data), { data: data, headers: getHeaders() });

//�˵��ǭ�
export const API_GET_AcntList = data => baseRequest.get('/User/AcntList', { params: data, headers: getHeaders(getAuthToken()) });

//�s��ӤH���
export const API_PUT_EditUser = data => baseRequest.put('/User/EditUser', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�x�Ȥ�צC��
export const API_GET_TopUpModeList = data => baseRequest.get('/TopUp/TopUpModeList', { params: data, headers: getHeaders(getAuthToken()) });

//�x�Ȼ��P��
export const API_POST_CreateRecBill = data => baseRequest.post('/TopUp/CreateRecBill', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//���P���ʶR�����C��
export const API_GET_RecBillList = data => baseRequest.get('/TopUp/RecBillList', { params: data, headers: getHeaders(getAuthToken()) });

//�d�ݻ��P���x�ȻP�d��
export const API_GET_SumBill = data => baseRequest.get('/TopUp/SumBill', { params: data, headers: getHeaders(getAuthToken()) });


//�d�ݳ浧���P���ʶR����
export const API_GET_RecBillInfo = data => baseRequest.get('/TopUp/RecBillInfo', { params: data, headers: getHeaders(getAuthToken()) });

//�q��d�ߪ���
export const API_GET_CompositionList = data => baseRequest.get('/File/CompositionList', { params: data, headers: getHeaders(getAuthToken()) });

//�w���浧�g�@
export const API_GET_OrderQunInFo = data => baseRequest.get('/File/OrderQunInFo', { params: data, headers: getHeaders(getAuthToken()) });

export const API_GET_OrderAnsInFo = data => baseRequest.get('/File/OrderAnsInFo', { params: data, headers: getHeaders(getAuthToken()) });


//�]��
export const API_POST_OrderInquery = data => baseCRequest.post('/online/', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//�W�Ǥ@�g�g�@
export const API_POST_CreateWriting = data => baseRequest.post('/File/CreateWriting', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//���o�W���ɮפj�p
export const API_GET_ImgByteInfo = data => baseRequest.get('/File/ImgByteInfo', { params: data, headers: getHeaders(getAuthToken()) });


//#region ��x


//#region �]��
//�]��-�C��
export const API_POST_FinanceGet = data => baseRequest.post('/Finance/FinanceGet', JSON.stringify(data), { headers: getHeaders() });

//�]��-�b��-�W�ǵo��
export const API_POST_UplodadInvoicet = data => baseRequest.post('/Finance/UplodadInvoice', JSON.stringify(data), { headers: getHeaders() });

//#region ����
//����-���Ѯv�޲z�d��
export const API_GET_TeacherBaseData = data => baseRequest.get('/Distribute/TeacherBaseData', { params: data, headers: getHeaders() });

//����-�\���t�� �b���d��
export const API_GET_AccountUser = data => correctRequest.get('/Account/AccountUser', { params: data, headers: getHeaders() });

//����-���Ѯv�޲z�s��
export const API_PUT_TeacherBaseData = data => baseRequest.put('/Distribute/TeacherBaseData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//����-���Ѯv�޲z�s�W
export const API_POST_TeacherBaseData = data => baseRequest.post('/Distribute/TeacherBaseData', JSON.stringify(data), { headers: getHeaders() });

//����-�������Ѯv
export const API_POST_Assign = data => correctRequest.post('/Paper/Assign', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });

//����-�Ҩ���ƶפJ�D�t��
export const API_POST_Import = data => correctRequest.post('/DataImport/Import', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });

//����-��Ƭd�߶פJ�D�t��
export const API_POST_AllocateGet = data => baseRequest.post('/Distribute/AllocateGet', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });

//����-�f�֪��A��s
export const API_PUT_ReviewSave = data => baseRequest.put('/Distribute/ReviewSave', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�ȪA,����,�\���@��-���T�оǬd��
export const API_POST_VideoTeaGet = data => baseRequest.post('/CustSer/VideoTeaGet', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });


//#region �ȪA
//�ȪA-���A�U�Ԧ����
export const API_GET_DropDown = data => baseRequest.get('/CustSer/DropDown', { params: data, headers: getHeaders(getAuthToken()) });

//�ȪA-�d��
export const API_POST_CustSerGet = data => baseRequest.post('/CustSer/CustSerGet', JSON.stringify(data), { headers: getHeaders() });

//����-�f�֪��A��s
export const API_PUT_CustSer_ReviewSave = data => baseRequest.put('/CustSer/ReviewSave', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });


//�ȪA-�f�֬d��
export const API_POST_ReviewGet = data => baseRequest.post('/CustSer/ReviewGet', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });

//�ȪA-���T�оǹw��
export const API_PUT_ReserveSave = data => baseRequest.put('/CustSer/ReserveSave', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });

//#region �\��

//�\��
export const API_POST_ScoringGet = data => baseRequest.post('/Scoring/ScoringGet', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });

//�q��Ӹ`�d��
export const API_GET_Score_GetOrderList = data => baseRequest.get('/Score/GetOrderList', { params: data, headers: getHeaders(getAuthToken()) });