import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin, googleLogout } from '@react-oauth/google';
//import { API_SignUp, API_OAuth, API_SignIn } from '../../global/constants';
import { checkAuthToken } from "../../global/TokenUtil";

function Header() {
    var history = useHistory();
    const path = window.location.pathname;
    const currentPage = path.toLowerCase();
    const [UserData, setUserData] = useState();

    useEffect(() => {
        //檢查是否登入
        setUserData(localStorage.getItem("UserData"));

    }, []);

    /*google登入
    function OAuthLogin(token) {
        var dt =
        {
            AcntToken: token
        }
        //console.log(token);
        
        //第三方登入
        API_OAuth(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    //alert("登入成功");
                    //localStorage.setItem("UserData", JSON.stringify(data.result));
                    //window.location = "/";
                    checkAuthToken(data);

                    localStorage.setItem('UserData', JSON.stringify(data.result));
                    alert("登入成功");
                    var s = document.getElementById('sys_signin');
                    s.click();
                    setUserData(JSON.stringify(data.result));

                    localStorage.setItem('UB', JSON.stringify(data.result.ub));
                    //console.log(JSON.stringify(data.result.ub));
                    setUB(JSON.stringify(data.result.ub));
                }
                else if (data.code = "1036") {
                    setAcntName(data.result.name);
                    setAcntNo(data.result.email);
                    setAcntPwd("");
                    setSub(data.result.sub);
                    setSubType("1");
                    var s = document.getElementById('sys_signin');
                    s.click();
                    var s = document.getElementById('createBtn');
                    s.click();
                }
                else {
                    alert(data.message);
                }

            })
            .catch(err => {
                console.log(err);
            })
    }*/

    const [linkList, setLinkList] = useState([
        //{
        //    link: "/promote",
        //    name: "促銷",
        //},
        {
            link: "/finance",
            name: "財務",
        },
        {
            link: "/assign",
            name: "派卷",
        },
        {
            link: "/review",
            name: "客服",
        },
        {
            link: "/score",
            name: "閱卷",
        },
        {
            link: "/account",
            name: "帳號",
        },
        //{
        //    link: "/plain",
        //    name: "儲值方案",
        //}
    ])

    return (

        <header>
            <nav className="headerBar fixed-top">

                <div className="navbar navbar-expand-md navbar-light" style={{ maxWidth: "90%", margin: 0, padding: 0 }}  >

                    <a className="navbar-brand logo" href="/">
                        <img src="images/logo.png" style={{ "width": "6rem" }} />
                        <span className="wTit" style={{ aontweight: 600 }}>&nbsp;後台管理平台</span>
                    </a>

                    <button className="navbar-toggler ml-auto my-2" type="button" data-toggle="collapse" data-target="#headerBarList"
                        aria-controls="headerBarList" aria-expanded="false">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="headerBarList">

                        <ul className="navbar-nav barList ml-auto">
                            {
                                linkList == null || linkList.length == 0 ? null :
                                    linkList.map((x, i) =>
                                        <li key={i} className="nav-item p-2">
                                            <a className={"nav-link" + (currentPage == x.link ? " active" : "")} href={x.link} >{x.name}</a>
                                        </li>
                                    )
                                }
                            {
                                UserData == null ?
                                    <li className="nav-item p-2">
                                        <a id="signinRemind" data-toggle="modal" className="nav-link" href="#sys_signin">登入</a>
                                    </li>
                                    :
                                    <li className="nav-item p-2">
                                        <a id="signinRemind" data-toggle="modal" className="nav-link" href="#" onClick={Logout}><img src="images/登出.svg" style={{ width: "2rem" }} />登出</a>
                                    </li>
                            }
                            <li className="nav-item p-2">
                                <div className="signInBtn">
                                    <a className="nav-link">
                                        <span className="text-warning" style={{ color: "" }} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                        </svg></span>&nbsp;某某，您好
                                    </a>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>

        </header>
    );
}
export default Header;
