//�����ܾ�
//<input type="text" class="datepickerTW">
(function () {
    var dateNative = new Date();
    // ��0�禡
    var padLeft = function(str, len){
        if(str.length >= len){
            return str;
        }else{
            return padLeft(("0" + str), len);
        }
    };

    var funcColle = function(){
        this.onSelect = {
            basic: function (dateText, inst) {
                /*
                var yearNative = inst.selectedYear < 1911 ? inst.selectedYear + 1911 : inst.selectedYear;
                */
                dateNative = new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay);
                // �~���p��100�|�Q�ɦ�19**, �n���ҥ~�B�z
                var yearTW;
                if (twSettings.yearPadZero) {
                    if (inst.selectedYear > 1911) {
                        yearTW = padLeft((inst.selectedYear - 1911).toString(), 3);
                    } else {
                        yearTW = padLeft(inst.selectedYear.toString(), 3)
                    }
                } else {
                    if (inst.selectedYear > 1911) {
                        yearTW = inst.selectedYear - 1911;
                    } else {
                        yearTW = inst.selectedYear;
                    }
                }
                var monthTW = padLeft((inst.selectedMonth + 1).toString(), 2);
                var dayTW = padLeft(inst.selectedDay, 2);

                return yearTW + twSettings.splitMark + monthTW + twSettings.splitMark + dayTW;
            }
        };
    };

    var twSettings = {
        closeText: '����',
        prevText: '�W�Ӥ�',
        nextText: '�U�Ӥ�',
        currentText: '����',
        changeYear: true, //��ʭק�~
        changeMonth: true, //��ʭק��
        yearRange: '1912:' + dateNative.getFullYear(),
        monthNames: [
        '�@��','�G��','�T��','�|��','����',
        '����','�C��','�K��','�E��','�Q��',
        '�Q�@��','�Q�G��'
        ],
        monthNamesShort: [
        '�@��','�G��','�T��','�|��','����',
        '����','�C��','�K��','�E��','�Q��',
        '�Q�@��','�Q�G��'
        ],
        dayNames: ['�P����','�P���@','�P���G','�P���T','�P���|','�P����','�P����'],
        dayNamesShort: ['�P��','�P�@','�P�G','�P�T','�P�|','�P��','�P��'],
        dayNamesMin: ['��','�@','�G','�T','�|','��','��'],
        weekHeader: '�P',
        dateFormat: 'yy/mm/dd',
        splitMark: '/', // ���Φ~��骺�лx
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: '',
        yearPadZero: false,
        beforeShow:  function() {
            setTimeout(function () {
                $('.ui-datepicker').css('z-index', 1000);
            }, 0);
            }
            // ,defaultLastYear: false
            /*
                ���S���]defaultDate�ɡA����yearRange��defaultDate
                ����defaultLastYear�]�w�w�]���̫�@�~or�Ĥ@�~
            */
    };

    // ��yearText��������
    var replaceYearText = function(){
        var $yearText = $('.ui-datepicker-year');

        if(twSettings.changeYear !== true){
            $yearText.text('����' + dateNative.getFullYear() - 1911);
        }else{
            // �U�Կ��
            /*
                        if($yearText.prev('span.datepickerTW-yearPrefix').length === 0){
                            $yearText.before("����  ");
                        }
            */
            $yearText.children().each(function () {
                if (parseInt($(this).text(), 10) > 1911) {
                    $(this).text(parseInt($(this).text(), 10) - 1911);
                }
            });
        }
    };

    $.fn.datepickerTW = function(options){
        if (typeof options === "undefined") {
            options = {};
        }

    var fn = new funcColle();
    // setting on init,
    if(typeof options == 'object'){
            //onSelect�ҥ~�B�z, �קK�л\
        if(typeof options.onSelect == 'function'){
            fn.onSelect.newFunc = options.onSelect;
        }

        options.onSelect = function(dateText, inst){
            var outputValue = fn.onSelect.basic(dateText, inst);

        if(twSettings.yearPadZero){
            outputValue = padLeft(outputValue, 9);
        }
        $(this).val(outputValue);

        if(typeof fn.onSelect.newFunc === 'function'){
            fn.onSelect.newFunc(outputValue, inst);
            }
        };

        // year range���W�Ʀ��褸, �p��1911���Ʀr���|�Q��������~
        if (options.yearRange) {
            var temp = options.yearRange.split(':');
            for (var i = 0; i < temp.length; i += 1) {
                //����e�B�z
                if (parseInt(temp[i], 10) < 1) {
                    temp[i] = parseInt(temp[i], 10) + 1911;
                } else {
                    if (parseInt(temp[i], 10) < 1911) {
                        temp[i] = parseInt(temp[i], 10) + 1911;
                    } else {
                        temp[i] = temp[i];
                    }
                }
            }
            options.yearRange = temp[0] + ':' + temp[1];
        }

    // �w�]default Date
        if (options.defaultDate) {

        } else if (options.yearRange) {
            var temp = options.yearRange.split(':');

            if (options.defaultLastYear) {
                options.defaultDate = temp[1] - new Date().getFullYear() + 'y';
            } else {
                options.defaultDate = temp[0] - new Date().getFullYear() + 'y';
            }
        } else if ($.trim($(this).val()) != '' && $(this).val() != undefined) {
            var tempDate = $(this).val().split(twSettings.splitMark);
            var tempYear = tempDate[0];

            var year;
            if (parseInt(tempYear, 10) < 1911) {
                year = padLeft((parseInt(tempYear, 10) + 1911).toString(), 4);
            } else {
                year = parseInt(tempYear, 10);
            }

            options.defaultDate = year - new Date().getFullYear() + 'y';
        }
    }

        // setting after init
        if (arguments.length > 1) {
            // �ثe�٨S�Q�쥿�`���Ѫk, �����ഫ��init setting obj���Φ�
            if (arguments[0] === 'option') {
                options = {};
                options[arguments[1]] = arguments[2];
            }
        }

        // override settings
        $.extend(twSettings, options);

        // init
        $(this).datepicker(twSettings);

        // beforeRender
        $(this).click(function () {
            var isFirstTime = ($(this).val() == '');
            // year range and default date
            if ((twSettings.defaultDate || twSettings.yearRange) && isFirstTime) {
                /*              ����year range��, select��l�Ƴ]��range���̥��~
                                �w�վ��W��"�w�]default Date"��
                                if(twSettings.defaultDate){
                                    $(this).datepicker('setDate', twSettings.defaultDate);
                                }
                 
                                // �o�q�B�z���n�A�]���u�~�v�u��F�~�[�A��javascript��ڭ��٬O�쥻���~
                                // ����year range��, select��l�Ƴ]��range���̥��~
                                if(twSettings.yearRange){
                                    var $yearSelect = $('.ui-datepicker-year'),
                                        nowYear = twSettings.defaultDate
                                            ? $(this).datepicker('getDate').getFullYear()
                                            : dateNative.getFullYear();
                 
                                    $yearSelect.children(':selected').removeAttr('selected');
                                    if($yearSelect.children('[value=' + nowYear + ']').length > 0){
                                        $yearSelect.children('[value=' + nowYear + ']').attr('selected', 'selected');
                                    }else{
                                        $yearSelect.children().last().attr('selected', 'selected');
                                    }
                                }
                */
            } else {
                var tempDate = $(this).val().split(twSettings.splitMark);

                if (tempDate.length != 3) {
                    $(this).datepicker('setDate', new Date());
                } else {
                    var tempYear = tempDate[0];
                    var tempMonth = tempDate[1] - 1;
                    var tempDay = padLeft(tempDate[2], 2);

                    var year;
                    if (parseInt(tempYear, 10) < 1911) {
                        year = padLeft((parseInt(tempYear, 10) + 1911).toString(), 4);
                    } else {
                        year = parseInt(tempYear, 10);
                    }

                    dateNative = new Date(year, tempMonth, tempDay);

                    $(this).datepicker('setDate', dateNative);
                }
            }

            var yearTW;
            if (twSettings.yearPadZero) {
                if (dateNative.getFullYear() > 1911) {
                    yearTW = padLeft((dateNative.getFullYear() - 1911).toString(), 3);
                } else {
                    yearTW = padLeft(dateNative.getFullYear().toString(), 3);
                }
            } else {
                if (dateNative.getFullYear() > 1911) {
                    yearTW = dateNative.getFullYear() - 1911;
                } else {
                    yearTW = dateNative.getFullYear();
                }
            }
            var monthTW = padLeft((dateNative.getMonth() + 1).toString(), 2);
            var dayTW = padLeft(dateNative.getDate().toString(), 2);

            $(this).val(yearTW + twSettings.splitMark + monthTW + twSettings.splitMark + dayTW);

            replaceYearText();

            if (isFirstTime) {
                $(this).val('');
            }
        });

        // afterRender
        $(this).focus(function () {
            replaceYearText();
        });

        return this;
    };
})();
//$('.datepickerTW').datepickerTW({
//    changeYear: true,
//    changeMonth: true,
//    dateFormat: 'yy-mm-dd',
//    // yearRange: '-10:2018',
//    // defaultDate: '86-11-01',

//});
//�w�����D
//�|���s�@��1911�٤p(����e)�����p�A�ҥH�p�G����~���Ӧ�ơA�N�|�ܦ� 1910 1911 1 2 3�A��F1911�A������^�ǥi��N�u���|�ܦ� ����1911�~�A�]�����A�Ω�s�@�i�H������~�e�����v���A�Яd�N
//���M���䴩 dateFormat: 'yy-mm-dd' ���榡�A�i�H�մ� yy mm dd �����ǡA������ϥΤj�g��MM�A�p�G�Τj�g���|�ܦ^�^����