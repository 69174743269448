import "../css/style.css";
//import Loginform from "../components/Login/loginform";
import "../css/bootstrap.min.css";
import "../js/jquery.min.js";
//import "../js/bootstrap.min.js"; 

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import Header from "../components/partial/header"
import { API_Carousel } from '../global/constants';
function Maintenance() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const [Carousel, setCarousel] = useState([{}]);
    useEffect(() => {
        document.body.style.overflow = "hidden";


    });
    return (
        <>
            <div class="main" style={{ fontSize: "2.5rem", padding: "2rem", backgroundColor: "#b8dce8", width: "100%", height: "100vh" }}>網站施工中...</div>
        </>
    );
}


export default Maintenance;
