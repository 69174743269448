import "../css/style.css";

import React, { useState, useEffect } from "react";
import {
    API_GET_TeacherBaseData,
    API_GET_AccountUser,
    API_PUT_TeacherBaseData,
    API_POST_TeacherBaseData,
    API_POST_Assign,
    API_POST_Import,
    API_POST_VideoTeaGet,
    API_GET_DropDown,
    API_POST_AllocateGet,
    API_PUT_ReviewSave,
    API_GET_OrderQunInFo,
    API_GET_Score_GetOrderList
} from "../global/constants.js";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import rocDatePicker from "../js/rocDatePicker";
import { Clone } from "../global/ToolUtil.js";



function Assign() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");

    //icon
    const CheckSVG = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill text-primary" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </svg>
    )
    const EditSVG = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square text-primary" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
        </svg>
    )
    const NoSvg = () => (
        < svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill text-danger" viewBox="0 0 16 16" >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
        </svg >
    )
    const FileSvg = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-spreadsheet-fill text-primary" viewBox="0 0 16 16">
            <path d="M6 12v-2h3v2H6z" />
            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM3 9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2H3V9z" />
        </svg>
    )
    //icon End
    const warnMSG = {
        'unDefindOrder': `網路連線失敗，請檢查網路或稍後查詢`,
        'unSelect': `請選擇可上傳檔案`,
        'noTeacher': `請選擇派遣老師`,
        'noOrder': `請選擇訂單`,
        'noReAssign':`禁止重新派卷`,
    };
    const [search, setSearch] = useState({
        "OrderNo": "",//--訂單編號
        "SDate": "", //--訂單時間起日
        "EDate": "", //--訂單時間迄日
        "OnlineState": ""//--批閱狀態
    }); //搜索條件
    const [dropSearch, setDropSearch] = useState([{}])//搜索下拉選單
    const [dataTable, setDataTable] = useState([{}]);//主要資料
    
    const [tercherList, setTercherList] = useState([{}]); // 批改老師列表
    const [tercherList2, setTercherList2] = useState([{}]); // 批改老師列表(取消時 取得 原先資料)
    const [DropDownTAcnt, setDropDownTAcnt] = useState(""); //老師帳號下拉
    const [assignOrder, setAssignOrder] = useState({
        "ExamNo": `EZWrite`,//考試代碼
        "Rater": "",  //老師帳號
        "StudNo": "",//訂單編號
        "PaperNo": "01",//試卷代碼之後會另外抓數值
        "StudPlain": "一對一線上輔導",//訂單方案
        "isNew": false//可派卷
    }); //派卷訂單資料容器
    const [teaGet, setTeaGet] = useState({});//GoogleMeeting查詢結果容器

    //單筆訂單
    const [orderFList, setOrderFList] = useState([]);
    const [orderOList, setOrderOList] = useState([]);

    const [auditRes, setAuditRes] = useState({
        "OrderOID": 1, //--閱卷流水號
        "OnlineCFState": "1"//--批改審核: 0 - 未審; 1 - 通過; 4 - 未通過
    })//審核結果

    function getData() {
        API_GET_DropDown()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    //console.log(data);
                    setDropSearch(data.result.onlineStateArray);
                }
                else {
                    alert(data.message);
                    localStorage.clear();
                    history.push("/")
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    
    useEffect(() => {
        //檢查是否登入
        getData();
        getTable(0)
    }, []);

    function getTable(setP) {
        API_POST_AllocateGet(search)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    console.log(data.result);
                    setDataTable(data.result);
                    setTableLength(data.result.length);
                    if (!setP) { setCurPage(0); }
                    else { setCurPage(setP); }
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //搜索條件變更
    function searchState(e, vName) {
        setSearch(function (prev) {
            let newSearch = { ...prev };
            switch (vName) {
                case "OrderNo":
                    newSearch.OrderNo = e.target.value;
                    break;
                case "SDate":
                    newSearch.SDate = e.target.value;
                    break;
                case "EDate":
                    newSearch.EDate = e.target.value;
                    break;
                case "OnlineState":
                    newSearch.OnlineState = e.target.value;
                    break;
            }
        return newSearch;
        });
    }

    function hideModal() {
        $(".modal").modal('hide');
    }

    //訂單詳情
    function showOrder(ordNum) {
        //資料初始化
        API_GET_Score_GetOrderList({
            OrderNo: ordNum
        }).then((response) => response.data)
            .then((data) => {
                let setYet = false;
                console.log(data);
                if (data.code == "0000") {
                    if (Object.keys(data.result).length > 0) {
                        if (data.result.fileListInfo.length > 0) {
                            setOrderFList(data.result.fileListInfo);
                        }
                        if (data.result.oderListInfo && data.result.oderListInfo.length > 0) {
                            setOrderOList(data.result.oderListInfo[0]);
                            setYet = true;
                        }

                    }
                }
                if (setYet) {
                    $("#sys_orderView").modal('show');
                } else {
                    if (data.code == "0000") {
                        alert("伺服器查無資料 或 網路不穩無法加載圖片");
                    } else {
                        alert(data.message);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //派遣訂單給老師
    function showAssign(choosen) {

        //取得目前可派卷老師
        API_GET_TeacherBaseData()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    //console.log(data);
                    setTercherList(data.result);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
        //清空上次選擇
        $('[name="selectTeacher"]').each(function (index, order) {
            $(this).prop("checked", false)
        });

        let exsitOrder = false,
            whatPlain = "",//方案名稱
            wasTeachter = null,//批改老師
            whatPlainCode = "01",//方案碼
            whatImgPath = "/WriteImage/230009201";//圖片路徑

        //檢查是否有該訂單
        dataTable.forEach((value, index) => {
            if (value.OrderNo == choosen) {
                //載入訂單資料
                exsitOrder = true;
                whatPlain = value.OnlineName;
                wasTeachter = value.TeacherOnlineAcnt;
                whatImgPath = value.SCPath;
                whatPlainCode = value.OnlinePaperNo
            }
        })
        
        if (!exsitOrder) return alert(`網路連線失敗，請重新登入或重新整理畫面`);

        //訂單是否已經被派發
        if (wasTeachter) {//已被派發
 
            //提取資料放入暫存容器
            var updatedAssignOrder = {
                ...assignOrder,
                StudNo: choosen,
                Rater: wasTeachter,
                StudPlain: whatPlain,
                isNew: false
            };
            setAssignOrder(updatedAssignOrder);

            $("#sys_teacherList").modal('show');
        }
        else {
            //尚未派發，優先向伺服器註冊訂單資料
            API_POST_Import({
                "StudList": [
                    {
                        "ExamNo": "EzWrite",//--固定碼
                        "StudNo": choosen,//--訂單編號
                        "StudCode1": "Z00",//--固定碼
                        "StudName": "",//--學生姓名
                        "PassNo": "",//--准考證號
                        "StudRem": ""//--學生備註
                    }
                ],
                "ReadList": [
                    {
                        "ExamNo": "EzWrite",//--固定碼
                        "StudSNo": `${choosen}${whatPlainCode}`,//--訂單編號 + 方案碼
                        "PaperNo": whatPlainCode,//--方案碼
                        "ImagePath1": whatImgPath//--圖片路徑
                    },
                ]
            })
                .then((response) => response.data)
                .then((data) => {
                    if (data.code == "0000") {
                        //註冊成功
                    } else if (data.code == "3028") {
                        //已經註冊跳過註冊;
                    } else {
                        throw new Error("伺服器拒絕訂單註冊");
                    }

                    //註冊完成，資料放入暫存容器
                    var updatedAssignOrder = {
                        ...assignOrder,
                        StudNo: choosen,
                        Rater: '',
                        StudPlain: whatPlain,
                        isNew: true
                    };
                    setAssignOrder(updatedAssignOrder);
                    $("#sys_teacherList").modal('show');
                })
                .catch((err) => {
                    console.log(err);
                    
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                    return alert("網路連線失敗，或是本地記憶體使用達上限");
                });
        }
    }
    //變更選擇的派遣老師
    function changeAssignTeatcher(e) {
        if (!assignOrder.isNew) return;
        var updatedAssignOrder = { ...assignOrder, Rater: e.target.value };
        setAssignOrder(updatedAssignOrder);
    }
    //更新派遣老師
    function updateAssign(e) {
        e.target.classList.add('d-none');
        //允許修改派卷
        if (!assignOrder.isNew) return alert(warnMSG['noReAssign']), e.target.classList.remove('d-none');
        //選擇老師
        if (!assignOrder.Rater) return alert(warnMSG['noTeacher']), e.target.classList.remove('d-none');
        //選擇訂單
        if (!assignOrder.StudNo) return alert(warnMSG['noOrder']), e.target.classList.remove('d-none');
        //試卷代碼確認
        /*if (!assignOrder.PaperNo) return alert(`驗證碼錯誤，請重新登入`);*/

        API_POST_Assign({
            "PaperList": [{
                "ExamNo": assignOrder.ExamNo,
                "Rater": assignOrder.Rater,
                "StudNo": assignOrder.StudNo,
                "PaperNo": assignOrder.PaperNo,
            }]
        })
            .then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {
                    hideModal();
                    getTable(curPage);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                alert(`網路連線失敗或網路不穩無法正確傳送封包`)
                //if (err.response.status === 401 || err.response.data.code == "1001") {
                //    localStorage.clear();
                //    history.push("/");
                //}
            })
            .finally(() => {
                e.target.classList.remove('d-none');
        })

    }

    //管理老師
    function teacherManage() {
        $("#sys_teacherManage").modal('show');

        API_GET_TeacherBaseData()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    console.log(data);
                    setTercherList(data.result);
                    var updatedAssignOrder = assignOrder.map(order => ({ ...order, isEdit: false }));
                    setAssignOrder(updatedAssignOrder);
                }
                else if (data.code == "4003") {
                  
                    setTercherList([]);
                } else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

        API_GET_AccountUser({
            ExamInfo: "Ezwrite"
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    //console.log(data);
                    setDropDownTAcnt(data.result.users);

                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //轉換為編輯模式
    function Edit(type, index) {
        if (type == 2 && tercherList[index].teacherID == null) { //新增老師 - 儲存按鈕
            console.log(tercherList);
            const dt = {
                TeacherName: tercherList[index].teacherName,
                TeacherOnlineAcnt: tercherList[index].teacherOnlineAcnt,
                TeacherGMail: tercherList[index].teacherGMail,
                TeacherGPwd: tercherList[index].teacherGPwd
            }
            console.log(dt);
            //呼叫API新增

            updata(dt,'add');
            //API_POST_TeacherBaseData(dt).then((response) => response.data)
            //    .then((data) => {
            //        //console.log(data);
            //        if (data.code == "0000") {//成功
            //            getData();
            //        } else {
            //            alert(data.message);
            //        }
            //        //console.log(localStorage.getItem('menulist'));
            //    })
            //    .catch(err => {
            //        console.log(err);
            //    })
        }
        else if (type == 3) { // 新增老師 - 取消按鈕
            setTercherList(function (prev) {
                return prev.filter((x, i) => i != index);
            });
        }
        else {
            //修改老師
            setTercherList(function (prev) {
                return prev.map((item, i) => {
                    if (i == index) {
                        if (type == 1) {  //按下修改
                            if (item.isEdit == true) { // 修改老師 - 儲存按鈕
                                //console.log(item);
                                const dt = {
                                    no:i,
                                    TeacherID: item.teacherID,
                                    TeacherName: item.teacherName,
                                    TeacherOnlineAcnt: item.teacherOnlineAcnt,
                                    TeacherGMail: item.teacherGMail,
                                    TeacherGPwd: item.teacherGPwd
                                }
                                //console.log(dt);
                                updata(dt,"edit");
                               
                            }
                            item.isEdit = type == 1 ? true : false;
                        } else { //按下的是修改-取消
                            //console.log(item);
                            item.teacherName = tercherList2[i].teacherName;
                            item.teacherGMail = tercherList2[i].teacherGMail;
                            item.teacherGPwd = tercherList2[i].teacherGPwd;
                            item.isEdit = false;
                        }

                    }
                    return item;
                });
            });
        }

    }
    function updata(dt, type) {
        
        if (type == 'add') {
            console.log(dt);
            API_POST_TeacherBaseData({
                TeacherName: dt.TeacherName,
                TeacherOnlineAcnt: dt.TeacherOnlineAcnt,
                TeacherGMail: dt.TeacherGMail,
                TeacherGPwd: dt.TeacherGPwd
            }).then((response) => response.data)
                .then((data) => {
                    //if (!checkAuthToken(data)) {
                    //    history.push("/");
                    //}
                    console.log(data);
                    if (data.code == "0000") {//成功

                        API_GET_TeacherBaseData()
                            .then((response) => response.data)
                            .then((data) => {
                                if (data.code == "0000") {
                                    console.log(data);
                                    setTercherList(data.result);

                                    setTercherList2(Clone(data.result));

                                }
                                else {
                                    alert(data.message);
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                                if (err.response.status === 401 || err.response.data.code == "1001") {
                                    localStorage.clear();
                                    history.push("/");
                                }
                            });
                        //tercherList[dt.no].isEdit = false;
                        //console.log(tercherList);

                    } else {
                        // setMsgStr(data.message);
                        alert(data.message);
                    }
                })
                .catch(err => {
                    console.log(err);

                })
        } else {
            API_PUT_TeacherBaseData({
                TeacherID: dt.TeacherID,
                TeacherName: dt.TeacherName,
                TeacherOnlineAcnt: dt.TeacherOnlineAcnt,
                TeacherGMail: dt.TeacherGMail,
                TeacherGPwd: dt.TeacherGPwd
            }).then((response) => response.data)
                .then((data) => {
                    //if (!checkAuthToken(data)) {
                    //    history.push("/");
                    //}
                    console.log(data);
                    if (data.code == "0000") {//成功

                        //getData();
                        setTercherList(function (prev) {
                            return prev.map((item, i2) => {
                                if (i2 == dt.no) {
                                    item.isEdit = false;
                                }
                                return item;
                            });
                        });
                        //tercherList[dt.no].isEdit = false;
                        //console.log(tercherList);

                    } else {
                        // setMsgStr(data.message);
                        alert(data.message);
                    }
                })
                .catch(err => {
                    console.log(err);

                })
        }


        
    }
    //編輯老師資料細節
    function nameChg(e, idx, vName) {
        setTercherList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    switch (vName) {
                        case "teacherName":
                            item.teacherName = e.target.value;
                            break;
                        case "teacherGMail":
                            item.teacherGMail = e.target.value;
                            break;
                        case "teacherOnlineAcnt":
                            item.teacherOnlineAcnt = e.target.value;
                            break;
                        case "teacherGPwd":
                            item.teacherGPwd = e.target.value;
                            break;
                    }

                }
                return item;
            });
        });
    }
    //新增老師 動態新增
    function addTeacher(e) {
        setTercherList(function (prev) {
            return [...prev, {
                teacherID: null,
                teacherName: null,
                teacherGMail: null,
                teacherOnlineAcnt: null,
                teacherGPwd: null,
                isEdit: true
            }];
        });
    }
    //下載報表
    function exportFile(idx) {
    }

    //審核訂單顯示
    function showAudit(order) {
        let exsitOrder = false,
            whatPlain = "",//方案名稱
            wasTeachter = null,//批改老師
            whatPaper = "";//OnlinePaperNo
        //測試 order = "230224020";
        //檢查是否有該訂單
        dataTable.forEach((value, index) => {
            if (value.OrderNo == choosen) {
                //載入訂單資料
                exsitOrder = true;
                whatPlain = value.OnlineName;
                wasTeachter = value.TeacherOnlineAcnt;
                whatPaper = value.OnlinePaperNo
            }
        })

        if (!exsitOrder) return alert('網路異常，查無訂單')
        //審核訂單 order
        var updatedAuditRes = {
            ...auditRes,
            OrderOID: order,
            OnlineName: whatPlain,
            TeacherOnlineAcnt: wasTeachter,
            OnlineCFState: 1,
            OnlinePaperNo: whatPaper
        };
        $('[name="aduitRes"]').each(function (index, order) {
            $(this).prop("checked", false)
        });
        setAuditRes(updatedAuditRes);
        $("#sys_audit").modal('show');

    }
    function changeAudit(e) {
        var updatedAuditRes = {
            OnlineCFState: e.target, value
        };
        setAuditRes(updatedAuditRes);
    }
    function sendAudit() {
        //審核訂單編輯更新 取得Api
        API_PUT_ReviewSave({
            "OrderOID": auditRes.OrderOID,
            "OnlineCFState": String(auditRes.OnlineCFState),
            "OnlinePaperNo":auditRes.OnlinePaperNo
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    hideModal();
                    getTable(curPage);
                }
                else {
                    alert(data.message);
                }
                hideModal();
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //顯示已預約GoogleMeeting
    function signMeeting(order) {
        //取得api 代入視訊時間是否安排
        API_POST_VideoTeaGet({
            "OrderOID": order//--閱卷流水號
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    //預約
                    console.log(data.result[0])
                    setTeaGet(data.result[0]);
                    $("#sys_booking").modal('show');
                } else if (data.code == "4003") {
                    //尚未預約;
                    alert(`訂單編號：${order}請洽客服人員進行預約設定`);
                } else {
                    throw new Error("伺服器拒絕查詢");
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //換頁 Start 資料容器
    const [eachPage, setEachPage] = useState(10);//每頁筆
    const [curPage, setCurPage] = useState(0);//當前頁面
    const [tableLength, setTableLength] = useState(0);//全部資料容器
    //setTableLength(data.result.length);setCurPage(0); 動作: 取得新資料 並 回到第一頁
    //呼叫 <PageNav alLength={資料長度} eachPage={每頁筆數} />
    //表格增加if (i < curPage * eachPage || i > (curPage + 1) * eachPage-1) { return }
    function PageNav(prop) {

        var history = useHistory();


        const [totalPage, setTotalPage] = useState(1);
        const [eachPage, setEachPage] = useState(10);

        useEffect(() => {
            let al = parseInt(prop.alLength) || 0,
                eP = parseInt(prop.eachPage) || 10;
            //console.log(prop)
            //console.log(al)
            setEachPage(eP);

            if (Math.ceil(al / eP)) {
                setTotalPage(Math.ceil(al / eP));
            } else {
                setTotalPage(1);
            }

        }, [prop]);

        //換頁
        function pageDirct(e) {
            let forward = e.target.dataset.pagemove, calPage = curPage;
            if (forward == "last") { calPage--; }
            else if (forward == "next") { calPage++; }
            else {
                calPage = parseInt(forward) || 0;
            }
            if (calPage <= 0) { calPage = 0 }
            else if (calPage >= totalPage - 1) { calPage = totalPage - 1; }


            setCurPage(calPage);
        }

        return (
            <div id="swichPageNav" className="row justify-content-center" data-curpage={curPage}>
                <div className="col-auto">
                    <a className="link-primary mx-2" data-pagemove="last" onClick={pageDirct}>上一頁</a>
                </div>
                <div className="col-auto">
                    {(curPage > 5 ?
                        <a className="link-primary mx-2" data-pagemove={(curPage - 5)} onClick={pageDirct} >...</a >
                        : "")}
                    {Array.from({ length: totalPage }, (_, index) => {
                        if (index < curPage - 5 || index > curPage + 5) {
                            return null;
                        }
                        return (
                            <a key={index} className="link-primary px-1 mx-1" data-pagemove={index} onClick={pageDirct} >
                                {(index == curPage ? `[${(index + 1)}]` : index + 1)}
                            </a>
                        )
                    })}
                    {(curPage + 5 < totalPage ?
                        <a className="link-primary mx-2" data-pagemove={(curPage + 5)} onClick={pageDirct} >...</a >
                        : "")}
                </div>
                <div className="col-auto">
                    <a className="link-primary mx-2" data-pagemove="next" onClick={pageDirct} >下一頁</a >
                </div>
            </div>
        );
    }
    //換頁End

    //批改期限判定
    function CalOverDate(prop) {
        let { odate } = prop, { nowStatus } = prop;
        //測試 odate = new Date('2023-03-02');
        if (odate) {
            if (nowStatus != "已審核") {
                let oldDate = new Date(odate);
                odate = new Date(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate()).setHours(0, 0, 0, 0);
                let toDay = new Date().setHours(0, 0, 0, 0);
                if (toDay >= odate) {
                    //console.log('Today is the same as or before odate');
                    return <td scope="col" className="text-danger">{new Date(odate).toISOString().split('T')[0]}</td>
                }/* else {
                    //console.log('Today is after odate');
                }*/
            }
            return <td scope="col">{new Date(odate).toISOString().split('T')[0]}</td>
        } else {
            return <td scope="col">無效日期</td>
        }
    }

    //測試用function
    function consoleFuc() {
        console.log(dataTable)
    }

    return (
        <>
            <div className="top"></div>
            <div id="main" className=""
                style={{ fontSize: "1.2rem", width: "100%", height: "100vh", position: "relative", backgroundColor: "#fff", overflowX: "hidden", overflowY: "scroll" }} >
                {/*backgroundImage: "url(images/service/service-bg.jpg)", backgroundPosition: "center center", backgroundSize: "cover"*/}
                <div style={{ height: "100px" }} >{/*blank for header*/}</div>
                <div className="row px-lg-5 px-2">

                    {/*搜尋列*/}<div className="col-12 pb-2">
                        <div className="row">
                            <div className="col-auto py-2">
                                {/*<button type="button" onClick={consoleFuc} >測試</button>*/}
                                <span>訂單日期：</span>
                                <input type="date" placeholder="2022/01/02" className="datepicker" value={search.SDate} onChange={(e) => searchState(e, "SDate")} />
                                <span className="px-3">~</span>
                                <input type="date" placeholder="2022/01/02" className="datepickerTW" value={search.EDate} onChange={(e) => searchState(e, "EDate")} />
                            </div>
                            <div className="col-auto py-2">
                                <span htmlFor="orderNo">訂單編號：</span>
                                <input id="orderNo" type="text" placeholder="xxxxxxxxx" value={search.OrderNo} onChange={(e) => searchState(e, "OrderNo")} />
                            </div>
                            <div className="col-auto py-2">
                                <span htmlFor="assignStatus">狀態：</span>
                                <select id="assignStatus" defaultValue="" value={search.OnlineState} onChange={(e) => searchState(e, "OnlineState")} >
                                    <option value="" selected>-全部-</option>
                                    {dropSearch.map((value, key) => (
                                        <option key={key} value={ value.id} >{value.name}</option>
                                        ))}
                                </select>
                            </div>
                            <div className="col-auto py-2">
                                <button type="button" className="btn btn-primary mx-2" onClick={getTable} >搜索</button>
                            </div>
                            <div className="col-auto ml-auto">
                                <button type="button" className="btn btn-info mx-2" onClick={teacherManage} >批改老師管理</button>
                            </div>
                        </div>
                    </div>
                    {/*主要內容*/}<div className="col-12" style={{ minHeight: `calc(${eachPage} * 56px)` }} >
                        <table className="table table-striped border-bottom">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-primary">訂單日期-時間</th>
                                    <th scope="col" className="text-primary">訂單編號</th>
                                    <th scope="col" className="text-primary">方案名稱</th>
                                    <th scope="col" className="text-primary">狀態</th>
                                    <th scope="col" className="text-primary">批改期限</th>
                                    <th className="text-center text-primary" scope="col">批改老師</th>
                                    <th scope="col" className="text-primary">批改結果</th>
                                    <th className="text-center text-primary" scope="col">批改審核</th>
                                    <th scope="col" className="text-primary">視訊教學</th>
                                    <th scope="col" className="text-primary">視訊教學日期時間</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(dataTable.length > 0 ?
                                    dataTable.map((value, key) => {
                                        if (!value || Object.keys(value).length == 0) return null;
                                        if (key < curPage * eachPage || key > (curPage + 1) * eachPage - 1) { return null }
                                        return (
                                            <tr key={key} >
                                                {/*訂單日期*/}
                                                <td scope="col">{value.OrderDate}</td>
                                                {/*訂單編號*/}
                                                <td scope="col"><div className="btn btn-link link-primary" onClick={(e) => showOrder(value.OrderNo)} >{value.OrderNo}</div></td>
                                                {/*方案名稱*/}
                                                <td scope="col">{value.OnlineName}</td>
                                                {/*狀態*/}
                                                <td scope="col">{value.State}</td>
                                                {/*批改期限 算超過今天 且未通過 紅字 除此外僅顯示正常字色*/}
                                                <CalOverDate odate={value.OnlineDate} nowStatus={value.State} />
                                                {/*批改老師*/}
                                                <td className="text-center" scope="col">
                                                    {(value.TeacherOnlineAcnt ?
                                                        <a className="link-primary" onClick={(e) => showAssign(value.OrderNo)} >{value.TeacherOnlineAcnt}</a>
                                                        :
                                                        <button className="btn btn-primary" type="button" onClick={(e) => showAssign(value.OrderNo)} >派卷</button>
                                                    )}
                                                </td>
                                                {/*批改結果*/}
                                                <td scope="col">{(
                                                    value.SCPath == null ?
                                                        "" :
                                                        <a className="link-primary" href={value.SCPath + value.SCCaption} target="blank">{value.SCCaption}</a>
                                                )}
                                                </td>
                                                {/*批改審核*/}
                                                <td className="text-center" scope="col">{(
                                                    value.State == "待審核"
                                                        ?
                                                        <button className="btn btn-primary" type="button" onClick={(e) => showAudit(value.OrderNo)} >審核</button>
                                                        :
                                                        value.CFState
                                                )}</td>
                                                {/*視訊教學*/}
                                                <td scope="col">{(value.Videoteach == "已約定" ?
                                                    <button className="btn btn-primary" onClick={(e) => signMeeting(value.OrderNo)}>查看</button>
                                                    :
                                                    (value.VOPath == null ? value.Videoteach : <a href={value.VOPath + value.Videoteach} target="blank">value.Videoteach</a>)

                                                )}</td>
                                                <td scope="col">
                                                    {/*{(value.OnlineNo == "C" ? value.ClassRDate : "")}*/}
                                                    {(value.OnlineNo != "" ? value.ClassRDate : "")}
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td className="text-center" colSpan="10">查無資料</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                    {/*換頁*/}<div className="col-12 py-3" style={{ backgroundColor: "#f2f2f2" }} >
                        <PageNav alLength={tableLength} eachPage={eachPage} />
                    </div>
                </div>

                <div className="footer sticky-bottom pt-5">
                    <div className="row text-center justify-content-center">
                        <div className="col-12">
                            <p>COPYRIGHT@<img src="images/EZwriting-txt.png" />　407臺中市西屯區長安路一段83號5樓<br />
                                電話：(04)2315-2500　客服信箱：service@ezwritingbar.com.tw</p>
                        </div>
                    </div>
                </div>
            </div>

            {/*<!--訂單詳情-->*/}
            <div className="modal fade" id="sys_orderView" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "650px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-10">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>訂單編號：{orderOList.OrderNo || ""}</td>
                                            </tr>
                                            <tr>
                                                <td>方案：{orderOList.OnlineName || ""}</td>
                                            </tr>
                                            <tr>
                                                <td>作文題目：{orderOList.OrderQun || ""}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    題目圖檔：<br />
                                                    {
                                                        orderOList && Object.keys(orderOList).length > 0 && orderOList.ATTFPath ?
                                                            <img src={"/"+orderOList.ATTFPath} alt="題目圖檔.jpg" style={{ maxHeight: "200px", width: "auto", maxWidth: "100%" }} />
                                                            :
                                                            "(無)"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    作文圖檔：<br />
                                                    {
                                                        orderFList.map((x, i) =>
                                                            <img key={i} src={"/" + x.ATTFPath} alt={"作文圖檔" + (i + 1)} style={{ maxHeight: "200px", width: "auto", maxWidth: "100%" }} />
                                                        )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-2">
                                    <button type="button" className="btn btn-outline-primary" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--訂單詳情 END-->*/}

            {/*<!--派卷清單-->*/}
            <div className="modal fade" id="sys_teacherList" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "850px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-11">
                                    <h3 className="text-center">派卷</h3>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <td></td>
                                                <td colSpan="4">訂單編號：{assignOrder.StudNo}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td colSpan="4">方案：一對一線上輔導</td>
                                            </tr>
                                            <tr className="text-primary table-active">
                                                <td width="16px"></td>
                                                <th className="text-primary">帳號</th>
                                                <th className="text-primary">姓名</th>
                                                <th className="text-primary">Google帳號</th>
                                                <th className="text-primary">密碼</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/*如果訂單已經分派老師將Radio增加屬性Check*/}
                                            {/*並且disabled其他Radio*/}
                                            {tercherList.map((value, key) => {
                                                return (
                                                    <tr key={key} className={(assignOrder.isNew ? "" : (assignOrder.Rater == value.teacherOnlineAcnt ?"":"d-none"))}>
                                                        <td><input className={(assignOrder.isNew ? "" : "d-none")} type="radio" name="selectTeacher" value={value.teacherOnlineAcnt} onClick={changeAssignTeatcher} readOnly /></td>
                                                        <td>{value.teacherOnlineAcnt}</td>
                                                        <td>{value.teacherName}</td>
                                                        <td>{value.teacherGMail}</td>
                                                        <td>{value.teacherGPwd}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-4">
                                    <button type="button" className={(assignOrder.isNew ? "btn btn-primary mx-4" : "d-none")} id="upAssignBtn" onClick={updateAssign} >儲存</button>
                                    <button type="button" className="btn btn-outline-primary mx-4" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--派卷清單 END-->*/}

            {/*<!--管理老師-->*/}
            <div className="modal fade" id="sys_teacherManage" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "850px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-11">
                                    <h3 className="text-center">批改老師管理</h3>
                                    <table className="table">
                                        <thead>
                                            <tr className="text-primary">
                                                <th className="text-primary">帳號</th>
                                                <th className="text-primary">姓名</th>
                                                <th className="text-primary">Google帳號</th>
                                                <th className="text-primary">密碼</th>
                                                <th className="text-primary" style={{ whiteSpace: "nowrap" }} >編輯&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                                <th className="text-primary">報表</th>
                                                {/*<!--報表輸出依照:`1.老師,2.月份`作為主要分類依據，主要用於計件工資結算-->*/}
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {/*/*資料顯示*/}
                                            {(tercherList.length > 0 ?
                                                tercherList.map((x, i) => (
                                                    <tr key={i} >
                                                    <td>
                                                        {x.isEdit == true && x.teacherID == null ? (
                                                            <select defaultValue="" value={x.teacherOnlineAcnt} onChange={(e) => { nameChg(e, i, "teacherOnlineAcnt") }} data-required>
                                                                <option value="">請選擇帳號</option>
                                                                {DropDownTAcnt.map((y) => (
                                                                    <option value={y.userNo}>{y.userNo} </option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            x.teacherOnlineAcnt
                                                        )}
                                                    </td>
                                                    <td>
                                                        {x.isEdit == true ? (
                                                            <input type="text" className="w-100 p-0" value={x.teacherName} onChange={(e) => nameChg(e, i, "teacherName")} />
                                                        ) : (
                                                            x.teacherName
                                                        )}
                                                    </td>
                                                    <td>
                                                        {x.isEdit == true ? (
                                                            <input type="text" className="w-100 p-0" value={x.teacherGMail} onChange={(e) => nameChg(e, i, "teacherGMail")} />
                                                        ) : (
                                                            x.teacherGMail
                                                        )}
                                                    </td>
                                                    <td>
                                                        {x.isEdit == true ? (
                                                            <input type="text" className="w-100 p-0" value={x.teacherGPwd} onChange={(e) => nameChg(e, i, "teacherGPwd")} />
                                                        ) : (
                                                            x.teacherGPwd
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div className="fn-bar">
                                                            {
                                                                x.teacherID == null ?
                                                                    <div>
                                                                        <span className="btn btn-icon p-0 mr-1" onClick={(e) => Edit(2, i)}>
                                                                            <CheckSVG />
                                                                        </span>
                                                                        <span className="btn btn-icon p-0 ml-1" onClick={(e) => Edit(3, i)}>
                                                                            <NoSvg />
                                                                        </span>
                                                                    </div>

                                                                    :
                                                                    x.isEdit == true ?

                                                                        <div>
                                                                            <span className="btn btn-icon p-0 mr-1" onClick={(e) => Edit(1, i)}>
                                                                                <CheckSVG />
                                                                            </span>
                                                                            <span className="btn btn-icon p-0 ml-1" onClick={(e) => Edit(0, i)}>
                                                                                <NoSvg />
                                                                            </span>
                                                                        </div>
                                                                        :
                                                                        <span className="btn btn-icon" onClick={(e) => Edit(1, i)}>
                                                                            <EditSVG />
                                                                        </span>
                                                            }

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="btn btn-icon">
                                                            <FileSvg onClick={(e) => exportFile(i)} />
                                                        </span>
                                                    </td>
                                                    </tr>

                                                ))
                                                :
                                                ""
                                            )}


                                            {/*新增按鈕*/}
                                            <tr>
                                                <td colSpan="6">
                                                    <button type="button" className="text-primary btn" onClick={addTeacher} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                        </svg>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-4">
                                    <button type="button" className="btn btn-outline-primary mx-4" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--管理老師 END-->*/}

            {/*<!--審核-->*/}
            <div className="modal fade" id="sys_audit" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "850px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-11">
                                    <h3 className="text-center">批改審核</h3>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td colSpan="4">訂單編號：{auditRes.OrderOID}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">方案：{auditRes.OnlineName}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">批改老師：{auditRes.TeacherOnlineAcnt}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingLeft: "32px" }} >
                                                    <span>
                                                        <label htmlFor="aduitAprove">
                                                            <input className="form-check-input rounded-circle" type="radio" id="aduitAprove" name="aduitRes" value="1" onChange={changeAudit}/>通過
                                                        </label>
                                                    </span><br />
                                                    <span>
                                                        <label htmlFor="aduitRejectA">
                                                            <input className="form-check-input rounded-circle" type="radio" id="aduitRejectA" name="aduitRes" value="4" onChange={changeAudit} />不通過
                                                        </label>
                                                    </span><br />
                                                    {/*<span>*/}
                                                    {/*    <label htmlFor="aduitRejectB"><input className="form-check-input rounded-circle" type="radio" id="aduitRejectB" name="aduitRes" />不通過</label>，改派其他老師。*/}
                                                    {/*    <select className="form-select mx-2">*/}
                                                    {/*        {tercherList.map((x, i) => (*/}
                                                    {/*            <option value={x.teacherName} >{x.teacherName}</option>*/}
                                                    {/*        ))}*/}
                                                    {/*    </select>*/}
                                                    {/*</span>*/}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-4">
                                    <button type="button" className="btn btn-primary mx-4" onClick={sendAudit} >送出</button>
                                    <button type="button" className="btn btn-outline-primary mx-4" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--審核 END-->*/}

            {/*<!--一對一線上教學預約-->*/}
            <div className="modal fade" id="sys_booking" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "850px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-11">
                                    <h3 className="text-center">一對一線上教學預約</h3>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td colSpan="4">訂單編號： {teaGet.OrderNo}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">預約日期： {String(teaGet.ClassRDate).split('T')[0]}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">方案：{teaGet.OnlineName}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">批改老師帳號：{teaGet.TeacherOnlineAcnt}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">批改老師姓名：{teaGet.TeacherName}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">批改老師Google帳號：{teaGet.TeacherGMail}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">Google帳號密碼：{teaGet.TeacherGPwd}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">
                                                    Google Meting 網址：<br />
                                                    <input className="form-control my-2 border-0 user-select-all" type="text" id="" name="aduitRes" value={teaGet.ClassMeetUrl} readOnly />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-4">
                                    <button type="button" className="btn btn-outline-primary" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--一對一線上教學預約 END-->*/}

        </>
    );
}


export default Assign;