import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    API_POST_ScoringGet,
    API_GET_DropDown,
    API_POST_VideoTeaGet,
    API_GET_Score_GetOrderList
} from "../global/constants.js";
import { FormattedMessage } from "react-intl"; //多國語言套件

function score() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");

    const [dropSearch, setDropSearch] = useState([{}])//搜索下拉選單
    const [dataTable, setDataTable] = useState([{}]);
    const [search, setSearch] = useState({
        "OrderNo": "",//--訂單編號
        "SDate": "", //--訂單時間起日
        "EDate": "", //--訂單時間迄日
        "OnlineState": ""//--批閱狀態
    }); //搜索條件
    /*上傳影音方法待定
    const [imgByte, setImgByte] = useState(2 * 1024 * 1024*1024);

    const warnMSG = {
        'overSize': `檔案大小過大: 上傳檔案不能超過 ${parseInt(imgByte / 1024 / 1024)/1024} GB`,
        'unSelect': `請選擇可上傳檔案`,
        'type': `請上傳mp4檔`,
    };
    */
    const [openFileData, setOpenFileData] = useState({ Name: '', ImageBase64: '' });
    const [updateFile, setUpdateFile] = useState('');
    const [teaFile, setTeaFile] = useState({});

    const [orderFList, setOrderFList] = useState([]);
    const [orderOList, setOrderOList] = useState([]);

    function getData() {
        API_GET_DropDown()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    //console.log(data);
                    setDropSearch(data.result.onlineStateArray);
                }
                else {
                    alert(data.message);
                    localStorage.clear();
                    history.push("/")
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    useEffect(() => {
        //檢查是否登入
        
        getData();
        getTable()
    }, []);
    function getTable() {
        API_POST_ScoringGet(search)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    console.log(data);
                    setDataTable(data.result);
                    setTableLength(data.result.length);
                    setCurPage(0);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    //搜索條件變更
    function searchState(e, vName) {
        setSearch(function (prev) {
            let newSearch = { ...prev };
            switch (vName) {
                case "OrderNo":
                    newSearch.OrderNo = e.target.value;
                    break;
                case "SDate":
                    newSearch.SDate = e.target.value;
                    break;
                case "EDate":
                    newSearch.EDate = e.target.value;
                    break;
                case "OnlineState":
                    newSearch.OnlineState = e.target.value;
                    break;
            }
            return newSearch;
        });
    }

    function hideModal() {
        $(".modal").modal('hide');
    }

    function showOrder(ordNum) {
        API_GET_Score_GetOrderList({
            OrderNo: ordNum
        }).then((response) => response.data)
            .then((data) => {
                let setYet = false;
                console.log(data);
                if (data.code == "0000") {
                    if (Object.keys(data.result).length > 0) {
                        if (data.result.fileListInfo.length > 0) {
                            setOrderFList(data.result.fileListInfo);
                        }
                        if (data.result.oderListInfo && data.result.oderListInfo.length > 0) {
                            setOrderOList(data.result.oderListInfo[0]);
                            setYet = true;
                        }

                    }
                }
                if (setYet) {
                    $("#sys_orderView").modal('show');
                } else {
                    if (data.code == "0000") {
                        alert("伺服器查無資料 或 網路不穩無法加載圖片");
                    } else {
                        alert(data.message);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function showModulSel(orderNum) {
        //檢查order是否已經上傳過檔案
        //if (dataTable.orderNum.file)

        //沒有上傳過
        $("#sys_upFile").modal('show');
        document.getElementById('File').click();
        //儲存當前選擇更新檔案
        setUpdateFile(orderNum);
    }
    function selectFile(event) {
        if (Object.keys(event.target.files).length) {
            let fileSize = parseInt(event.target.files[0].size);
            //檢查檔案大小
            if (fileSize <= imgByte) {

                var reader = new FileReader(); //建立FileReader物件
                reader.readAsDataURL(event.target.files[0]); //以.readAsDataURL將上傳檔案轉換為base64字串
                var dataURL = reader.result; //設定變數dataURL為上傳圖檔的base64字串
                setOpenFileData(prevState => ({ Name: event.target.files[0].name, ImageBase64: dataURL }));

            } else {
                alert(warnMSG['overSize'])
            }
        }
    }

    function sendFile() {
        //檢查欲上傳檔案存在
        if (openFileData.Name && openFileData.ImageBase64) {
            //請求api
            //訂單編號updateFile
            //上傳檔案openFileData
        } else {
            alert(warnMSGG['unSelect'])
        }
    }

    //顯示已預約GoogleMeeting
    function signMeeting(order) {
        //取得api 代入視訊時間是否安排
        API_POST_VideoTeaGet({
            "OrderOID": order//--閱卷流水號
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    //預約
                    console.log(data.result[0])
                    setTeaGet(data.result[0]);
                    $("#sys_booking").modal('show');
                } else if (data.code == "4003") {
                    //尚未預約;
                    alert(`訂單編號：${order}請洽客服人員進行預約設定`);
                } else {
                    throw new Error("伺服器拒絕查詢");
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //呼叫上傳視窗
    function showModulSel(order) {
        $("#sys_upFile").modal('show');
        setTeaFile({
            classID: order
        })
        //document.getElementById('File').click();
    }
    //選擇檔案
    function selectFile() {
        console.log(event.target.files[0]);
        if (Object.keys(event.target.files).length) {
            let fileSize = parseInt(event.target.files[0].size);
            let fileType = event.target.files[0].type;
            //檢查檔案大小
            if (fileSize <= imgByte) {
                //檢查檔案副檔名是不是 mp4
                if (fileType == "application/mp4") {
                    var reader = new FileReader(); //建立FileReader物件
                    reader.readAsDataURL(event.target.files[0]); //以.readAsDataURL將上傳檔案轉換為base64字串
                    reader.onload = function () {
                        var dataURL = reader.result; //設定變數dataURL為上傳圖檔的base64字串

                        var name = event.target.files[0].name;
                        console.log(name.substr(0, name.lastIndexOf(".")));
                        setTeaFile(prevState => ({ FileName: name.substr(0, name.lastIndexOf(".")), Base64String: dataURL }));
                    }
                } else {
                    alert(warnMSG['type']);
                }
            } else {
                alert(warnMSG['overSize']);
            }
        }
    }
    //上傳檔案
    function sendFile() {
    }

    //換頁 Start 資料容器
    const [eachPage, setEachPage] = useState(10);//每頁筆
    const [curPage, setCurPage] = useState(0);//當前頁面
    const [tableLength, setTableLength] = useState(0);//全部資料容器
    //setTableLength(data.result.length);setCurPage(0); 動作: 取得新資料 並 回到第一頁
    //呼叫 <PageNav alLength={資料長度} eachPage={每頁筆數} />
    //表格增加if (i < curPage * eachPage || i > (curPage + 1) * eachPage-1) { return }
    function PageNav(prop) {

        var history = useHistory();


        const [totalPage, setTotalPage] = useState(1);
        const [eachPage, setEachPage] = useState(10);

        useEffect(() => {
            let al = parseInt(prop.alLength) || 0,
                eP = parseInt(prop.eachPage) || 10;
            //console.log(prop)
            //console.log(al)
            setEachPage(eP);

            if (Math.ceil(al / eP)) {
                setTotalPage(Math.ceil(al / eP));
            } else {
                setTotalPage(1);
            }

        }, [prop]);

        //換頁
        function pageDirct(e) {
            let forward = e.target.dataset.pagemove, calPage = curPage;
            if (forward == "last") { calPage--; }
            else if (forward == "next") { calPage++; }
            else {
                calPage = parseInt(forward) || 0;
            }
            if (calPage <= 0) { calPage = 0 }
            else if (calPage >= totalPage - 1) { calPage = totalPage - 1; }


            setCurPage(calPage);
        }

        return (
            <div id="swichPageNav" className="row justify-content-center" data-curpage={curPage}>
                <div className="col-auto">
                    <a className="link-primary mx-2" data-pagemove="last" onClick={pageDirct}>上一頁</a>
                </div>
                <div className="col-auto">
                    {(curPage > 5 ?
                        <a className="link-primary mx-2" data-pagemove={(curPage - 5)} onClick={pageDirct} >...</a >
                        : "")}
                    {Array.from({ length: totalPage }, (_, index) => {
                        if (index < curPage - 5 || index > curPage + 5) {
                            return null;
                        }
                        return (
                            <a key={index} className="link-primary px-1 mx-1" data-pagemove={index} onClick={pageDirct} >
                                {(index == curPage ? `[${(index + 1)}]` : index + 1)}
                            </a>
                        )
                    })}
                    {(curPage + 5 < totalPage ?
                        <a className="link-primary mx-2" data-pagemove={(curPage + 5)} onClick={pageDirct} >...</a >
                        : "")}
                </div>
                <div className="col-auto">
                    <a className="link-primary mx-2" data-pagemove="next" onClick={pageDirct} >下一頁</a >
                </div>
            </div>
        );
    }
    //換頁End

    //批改期限判定
    function CalOverDate(prop) {
        let { odate } = prop, { nowStatus } = prop;
        //測試 odate = new Date('2023-03-02');
        if (odate) {
            if (nowStatus != "已審核") {
                let oldDate = new Date(odate);
                odate = new Date(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate()).setHours(0, 0, 0, 0);
                let toDay = new Date().setHours(0, 0, 0, 0);
                if (toDay >= odate) {
                    //console.log('Today is the same as or before odate');
                    return <td scope="col" className="text-danger">{new Date(odate).toISOString().split('T')[0]}</td>
                }/* else {
                    //console.log('Today is after odate');
                }*/
            }
            return <td scope="col">{new Date(odate).toISOString().split('T')[0]}</td>
        } else {
            return <td scope="col">無效日期</td>
        }
    }

    //測試用function
    function consoleFuc() {
        console.log(dataTable)
    }

    return (
        <>
            <div className="top"></div>
            <div id="main" className=""
                style={{ fontSize: "1.2rem", width: "100%", height: "100vh", position: "relative", backgroundColor: "#fff", overflowX: "hidden", overflowY: "scroll" }} >
                {/*backgroundImage: "url(images/service/service-bg.jpg)", backgroundPosition: "center center", backgroundSize: "cover"*/}
                <div style={{ height: "100px" }} >{/*blank for header*/}</div>
                <div className="row px-lg-5 px-2">
                    
                    {/*搜尋列*/}<div className="col-12 pb-2">
                        <div className="row">
                            <div className="col-auto py-2">
                                {/*<button type="button" onClick={consoleFuc} >測試</button>*/}
                                <span>訂單日期：</span>
                                <input type="date" placeholder="2022/01/02" className="datepicker" value={search.SDate} onChange={(e) => searchState(e, "SDate")} />
                                <span className="px-3">~</span>
                                <input type="date" placeholder="2022/01/02" className="datepickerTW" value={search.EDate} onChange={(e) => searchState(e, "EDate")} />
                            </div>
                            <div className="col-auto py-2">
                                <span htmlFor="orderNo">訂單編號：</span>
                                <input id="orderNo" type="text" placeholder="xxxxxxxxx" value={search.OrderNo} onChange={(e) => searchState(e, "OrderNo")} />
                            </div>
                            <div className="col-auto py-2">
                                <span htmlFor="assignStatus">狀態：</span>
                                <select id="assignStatus" defaultValue="" value={search.OnlineState} onChange={(e) => searchState(e, "OnlineState")} >
                                    <option value="" selected>-全部-</option>
                                    {dropSearch.map((value, key) => (
                                        <option key={key} value={value.id} >{value.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-auto py-2">
                                <button type="button" className="btn btn-primary mx-2" onClick={getTable} >搜索</button>
                            </div>

                        </div>
                    </div>
                    {/*主要內容*/}<div className="col-12" style={{ minHeight: `calc(${eachPage} * 56px)` }} >
                        <table className="table table-striped border-bottom">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-primary">訂單時間-日期</th>
                                    <th scope="col" className="text-primary">訂單編號</th>
                                    <th scope="col" className="text-primary">方案名稱</th>
                                    <th scope="col" className="text-primary">狀態</th>
                                    <th scope="col" className="text-primary">批改期限</th>
                                    <th scope="col" className="text-primary">批改</th>
                                    <th scope="col" className="text-primary">批改結果</th>
                                    <th scope="col" className="text-primary">視訊教學</th>
                                    <th scope="col" className="text-primary">視訊教學日期時間</th>
                                </tr>
                            </thead>
                            <tbody className="table-hover">
                                {dataTable.map((value, key) => {
                                    if (!value) return null;
                                    if (key < curPage * eachPage || key > (curPage + 1) * eachPage - 1) { return }
                                    return (
                                        <tr key={key} >
                                            {/*訂單時間-日期*/}
                                            <td>{value.OrderDate}</td>
                                            {/*訂單編號*/}
                                            <td><a className="link-primary" onClick={(e) => showOrder(value.OrderNo)} >{value.OrderNo}</a></td>
                                            {/*方案名稱*/}
                                            <td>{value.OnlineName}</td>
                                            {/*狀態*/}
                                            <td>{value.State}</td>
                                            {/*批改期限 算超過今天 且未通過 紅字 除此外僅顯示正常字色*/}
                                            <CalOverDate odate={value.OnlineDate} nowStatus={value.State} />
                                            {/*批改*/}
                                            <td>{(value.State == "批改中"
                                                ?
                                                <a className="btn btn-primary" href="/stru_Studetnt?order=asdfasdf" target="blank">批改</a>
                                                :
                                                "")}
                                            </td>
                                            {/*批改結果*/}
                                            <td>{(value.SCCaption
                                                ?
                                                <a className="link-primary" href={value.VOPath +'/'+value.SCCaption} target="blank">{value.SCCaption}</a>
                                                :
                                                "")}</td>
                                            {/*視訊教學*/}
                                            <td scope="col">
                                                {(
                                                    value.Videoteach == "已預約"
                                                        ?
                                                        //(時間超過
                                                        //?已經超過
                                                        //< button className="btn btn-primary" onClick={(e) => showModulSel(value.OrderNo)}>上傳</button>
                                                        //:還沒到
                                                        //<button className="btn btn-info" onClick={(e) => signMeeting(value.OrderNo)}>查看</button>
                                                        //)
                                                        <button className="btn btn-info" onClick={(e) => signMeeting(value.OrderNo)}>查看</button>
                                                        :
                                                        (
                                                            value.VOPath == null
                                                                ?
                                                                value.Videoteach 
                                                                :
                                                                <a className="link-primary" href={value.VOPath + value.Videoteach} target="blank">value.Videoteach</a>
                                                        )
                                                )}
                                            </td>
                                            {/*訊教學日期時間*/}
                                            <td scope="col">
                                                {(value.OnlineNo == "C" ? value.ClassRDate : "")}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/*換頁*/}<div className="col-12 py-3" style={{ backgroundColor: "#f2f2f2" }} >
                        <PageNav alLength={tableLength} eachPage={eachPage} />
                    </div>
                </div>

                <div className="footer sticky-bottom pt-5">
                    <div className="row text-center justify-content-center">
                    <div className="col-12">
                            <p>COPYRIGHT@<img src="images/EZwriting-txt.png" />　407臺中市西屯區長安路一段83號5樓<br />
                                電話：(04)2315-2500　客服信箱：service@ezwritingbar.com.tw</p>
                        </div>
                    </div>
                </div>
            </div>


            {/*<!--訂單詳情-->*/}
            <div className="modal fade" id="sys_orderView" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "650px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-10">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>訂單編號：{orderOList.OrderNo || ""}</td>
                                            </tr>
                                            <tr>
                                                <td>方案：{orderOList.OnlineName || ""}</td>
                                            </tr>
                                            <tr>
                                                <td>作文題目：{orderOList.OrderQun || ""}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    題目圖檔：<br />
                                                    {
                                                        orderOList && Object.keys(orderOList).length > 0 && orderOList.ATTFPath ?
                                                            <img src={"/" + orderOList.ATTFPath} alt="題目圖檔.jpg" style={{ maxHeight: "200px", width: "auto", maxWidth: "100%" }} />
                                                            :
                                                            "(無)"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    作文圖檔：<br />
                                                    {
                                                        orderFList.map((x, i) =>
                                                            <img key={i} src={"/" + x.ATTFPath} alt={"作文圖檔" + (i + 1)} style={{ maxHeight: "200px", width: "auto", maxWidth: "100%" }} />
                                                        )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-2">
                                    <button type="button" className="btn btn-outline-primary" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--訂單詳情 END-->*/}

            {/*<!--一對一線上教學預約-->*/}
            <div className="modal fade" id="sys_booking" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "850px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-11">
                                    <h3 className="text-center">一對一線上教學預約</h3>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td colSpan="4">訂單編號： 230301054</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">預約日期： 2023/02/10 09:30</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">方案：一對一線上輔導</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">批改老師帳號：AAAA</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">批改老師姓名：謝佳源</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">批改老師Google帳號：111000@summit-edu.com.tw</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">Google帳號密碼：Seat0001</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">
                                                    Google Meting 網址：<br />
                                                    <input className="form-control my-2 border-0" type="text" id="" name="aduitRes" value="meet.google.com/jzh-stuv-kzy" readOnly />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-4">
                                    <button type="button" className="btn btn-outline-primary mx-4" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--一對一線上教學預約 END-->*/}

            {/*<!--上傳檔案-->*/}
            <div className="modal fade" id="sys_upFile" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "650px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-10">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td><input type="file" id="File" onChange={selectFile} ></input></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-2">
                                    <button type="button" className="mx-4 btn btn-primary" onClick={sendFile} >送出</button>
                                    <button type="button" className="mx-4 btn btn-outline-primary" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--上傳檔案 END-->*/}

        </>
    );
}


export default score;