import "../css/style.css";

import React, { useState, useEffect } from "react";
import {
    API_POST_FinanceGet,
    API_POST_UplodadInvoicet
} from "../global/constants.js";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件

function finance() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");

    const [imgByte, setImgByte] = useState(3 * 1024 * 1024);
    const warnMSG = {
        'overSize': `檔案大小過大: 上傳檔案不能超過 ${parseInt(imgByte / 1024 / 1024)} MB`,
        'unSelect': `請選擇可上傳PDF檔案`,
        'type': `請上傳PDF檔`,
    };
    const [dataTable, setDataTable] = useState([{}]);

    const [openFileData, setOpenFileData] = useState({ FileName: '', Base64String: '' });
    const [InvoiceID, setInvoiceID] = useState('');

    const [BillBankNo, setBillBankNo] = useState(""); //收款帳號
    const [SDate, setSDate] = useState(""); //收款日期起日
    const [EDate, setEDate] = useState(""); //收款日期迄日

    //icon
    const UploadSVG = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
            <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
        </svg>
    )
    //icon end

    function getData() {

    }

    useEffect(() => {
        //檢查是否登入

        //getData();
        getTable();
    }, []);

    function getTable() {
        API_POST_FinanceGet({
            BillBankNo: BillBankNo,
            SDate: SDate,
            EDate: EDate
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    console.log(data);
                    setDataTable(data.result);
                    setTableLength(data.result.length);
                    setCurPage(0);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function hideModal() {
        $("#sys_upFile").modal('hide');
    }
    function showModulSel(InvoiceID) {
        //檢查order是否已經上傳過檔案
        //if (dataTable.orderNum.file)

        //沒有上傳過


        $("#sys_upFile").modal('show');
        //document.getElementById('File').click();
        //儲存當前選擇更新檔案
        setInvoiceID(InvoiceID);
    }
    function selectFile(event) {
        console.log(event.target.files[0]);
        if (Object.keys(event.target.files).length) {
            let fileSize = parseInt(event.target.files[0].size);
            let fileType = event.target.files[0].type;
            //檢查檔案大小
            if (fileSize <= imgByte) {
                //檢查檔案副檔名是不是 PDF
                if (fileType == "application/pdf") {
                    var reader = new FileReader(); //建立FileReader物件
                    reader.readAsDataURL(event.target.files[0]); //以.readAsDataURL將上傳檔案轉換為base64字串
                    reader.onload = function () {
                        var dataURL = reader.result; //設定變數dataURL為上傳圖檔的base64字串

                        var name = event.target.files[0].name;
                        console.log(name.substr(0, name.lastIndexOf(".")));
                        setOpenFileData(prevState => ({ name: name.substr(0, name.lastIndexOf(".")), FileName: name, Base64String: dataURL, type: fileType }));
                    }
                } else {
                  
                    alert(warnMSG['type']);
                }
            } else {
                alert(warnMSG['overSize']);
            }
        }
    }
    function sendFile() {
        //檢查欲上傳檔案存在
        console.log(openFileData);
        if (openFileData.FileName && openFileData.Base64String) {
            if (openFileData.type == "application/pdf") {
            //請求api
            //訂單編號updateFile
            //上傳檔案openFileData
            API_POST_UplodadInvoicet({
                InvoiceID: InvoiceID,
                InvoiceNo: openFileData.FileName,
                imgs: [openFileData]
            }).then((response) => response.data)
                .then((data) => {
                    if (data.code == "0000") {//成功
                        console.log(data);
                        getTable();
                        hideModal();
                    }
                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push('/');
                    }
                })
            } else {

                alert(warnMSG['type']);
            }
        } else {
            alert(warnMSG['unSelect'])
        }
    }

    //換頁 Start 資料容器
    const [eachPage, setEachPage] = useState(10);//每頁筆
    const [curPage, setCurPage] = useState(0);//當前頁面
    const [tableLength, setTableLength] = useState(0);//全部資料容器
    //setTableLength(data.result.length);setCurPage(0); 動作: 取得新資料 並 回到第一頁
    //呼叫 <PageNav alLength={資料長度} eachPage={每頁筆數} />
    //表格增加if (i < curPage * eachPage || i > (curPage + 1) * eachPage-1) { return }
    function PageNav(prop) {

        var history = useHistory();

        
        const [totalPage, setTotalPage] = useState(1);
        const [eachPage, setEachPage] = useState(10);

        useEffect(() => {
            let al = parseInt(prop.alLength) || 0,
                eP = parseInt(prop.eachPage) || 10;
            //console.log(prop)
            //console.log(al)
            setEachPage(eP);

            if (Math.ceil(al / eP)) {
                setTotalPage(Math.ceil(al / eP));
            } else {
                setTotalPage(1);
            }
            
        }, [prop]);

        //換頁
        function pageDirct(e) {
            let forward = e.target.dataset.pagemove, calPage = curPage;
            if (forward == "last") { calPage--; }
            else if (forward == "next") { calPage++; }
            else {
                calPage = parseInt(forward) || 0;
            }
            if (calPage <= 0) { calPage = 0 }
            else if (calPage >= totalPage - 1) { calPage = totalPage - 1; }


            setCurPage(calPage);
        }

        return (
            <div id="swichPageNav" className="row justify-content-center" data-curpage={curPage}>
                <div className="col-auto">
                    <a className="link-primary mx-2" data-pagemove="last" onClick={pageDirct}>上一頁</a>
                </div>
                <div className="col-auto">
                    {(curPage > 5 ?
                        <a className="link-primary mx-2" data-pagemove={(curPage - 5)} onClick={pageDirct} >...</a >
                        : "")}
                    {Array.from({ length: totalPage }, (_, index) => {
                        if (index < curPage - 5 || index > curPage + 5) {
                            return null;
                        }
                        return (
                            <a key={index} className="link-primary px-1 mx-1" data-pagemove={index} onClick={pageDirct} >
                                {(index == curPage ? `[${(index + 1)}]` : index + 1)}
                            </a>
                        )
                    })}
                    {(curPage + 5 < totalPage ?
                        <a className="link-primary mx-2" data-pagemove={(curPage + 5)} onClick={pageDirct} >...</a >
                        : "")}
                </div>
                <div className="col-auto">
                    <a className="link-primary mx-2" data-pagemove="next" onClick={pageDirct} >下一頁</a >
                </div>
            </div>
        );
    }
    //換頁End

    //測試用function
    function consoleFuc() {
        //console.log(dataTable);
        //console.log(totalPage);
        //console.log($('#swichPageNav').data('curpage'));
    }
    
    return (
        <>
            <div className="top"></div>
            <div id="main" className=""
                style={{ letterSpacing: "0.2rem", fontSize: "1.2rem", width: "100%", height: "100vh", position: "relative", backgroundColor: "#fff", overflowX: "hidden", overflowY: "scroll" }} >
                {/*backgroundImage: "url(images/service/service-bg.jpg)", backgroundPosition: "center center", backgroundSize: "cover"*/}
                <div style={{ height: "100px" }} >{/*blank for header*/}</div>
                <div className="row px-lg-5 px-2">

                    {/*搜尋列*/}<div className="col-12 pb-2">
                        <div className="row">
                            <div className="col-auto py-2">
                                {/*<button type="button" onClick={consoleFuc} >測試</button>*/}
                                <span>收款日期：</span>
                                <input type="date" placeholder="2022/01/02" value={SDate} onChange={(e) => { setSDate(e.target.value) }} />
                                <span className="px-3">~</span>
                                <input type="date" placeholder="2022/01/02" value={EDate} onChange={(e) => { setEDate(e.target.value) }} />
                            </div>
                            <div className="col-auto py-2">
                                <label>
                                    <span htmlFor="incomeNo">收款帳號：</span>
                                    <input id="incomeNo" type="text" placeholder="3888xxxxxxxxx" value={BillBankNo} onChange={(e) => { setBillBankNo(e.target.value) }} />
                                </label>
                            </div>
                            <div className="col-auto py-2">
                                <button type="button" className="btn btn-primary" onClick={getTable}>搜尋</button>
                            </div>
                        </div>
                    </div>
                    {/*主要內容*/}<div className="col-12" style={{ minHeight: `calc(${eachPage} * 56px)` }} >
                       
                        <table className="table table-striped position-relative border-bottom" style={{ zIndex: "1" }} >
                            <thead>
                                <tr >
                                    <th className="col-1 position-sticky text-primary align-middle bg-white border-0" scope="col" style={{top:"60px",zIndex:" 10",boxShadow:" inset 0px -2px 0px -1px #dee2e6, inset 0px 2px 0px -1px #dee2e6" }}>
                                        收款時間-日期</th>
                                    <th className="col-2 text-center align-middle position-sticky text-primary bg-white border-0" scope="col" style={{ top: "60px", zIndex: " 10", boxShadow: " inset 0px -2px 0px -1px #dee2e6, inset 0px 2px 0px -1px #dee2e6"}}>
                                        收款帳號</th>
                                    <th className="col-2 align-middle position-sticky text-primary bg-white border-0" scope="col" style={{ top: "60px", zIndex: " 10", boxShadow: " inset 0px -2px 0px -1px #dee2e6, inset 0px 2px 0px -1px #dee2e6"}}>
                                        產品名稱</th>
                                    <th className="col-1 text-center align-middle position-sticky text-primary bg-white border-0" scope="col" style={{ top: "60px", zIndex: " 10", boxShadow: " inset 0px -2px 0px -1px #dee2e6, inset 0px 2px 0px -1px #dee2e6"}}>
                                        金額</th>
                                    <th className="col-2 text-center align-middle position-sticky text-primary bg-white border-0" scope="col" style={{ top: "60px", zIndex: " 10", boxShadow: " inset 0px -2px 0px -1px #dee2e6, inset 0px 2px 0px -1px #dee2e6"}}>
                                        發票號碼</th>
                                    <th className="col-2 text-center align-middle position-sticky text-primary bg-white border-0" scope="col" style={{ top: "60px", zIndex: " 10", boxShadow: " inset 0px -2px 0px -1px #dee2e6, inset 0px 2px 0px -1px #dee2e6"}}>
                                        上傳發票</th>
                                </tr>
                            </thead>
                            <tbody className="table-hover">
                                {(dataTable.length > 0 ?
                                    dataTable.map((x, i) => {
                                        if (i < curPage * eachPage || i > (curPage + 1) * eachPage-1) { return }
                                        return (
                                            <tr key={i} >
                                                <td className="align-middle col-1">{x.PayDate}</td>
                                                <td className="align-middle text-center col-2">{x.BillBankNo}</td>
                                                <td className="align-middle col-2">{x.ToupCaption}</td>
                                                <td className="align-middle text-center col-1">{String(x.PayMoney).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}</td>
                                                <td className="align-middle text-center col-2">{x.InvoiceNo}</td>
                                                <td className="align-middle text-center col-2">{(x.ATTFCaption
                                                    ?
                                                    <a href={`../invoice/${x.ATTFPath}`} target="blank">{x.ATTFCaption}</a>
                                                    :
                                                    <div>
                                                        <span className="text-primary" onClick={(e) => showModulSel(x.InvoiceID)} ><UploadSVG /></span>
                                                        {/*<input type="file" id="file" accept="image/*" style={{ display: 'none' }} onChange={handleChange} />*/}
                                                    </div>
                                                )}</td>
                                            </tr>
                                        )
                                    }
                                    )
                                    : <tr>
                                        <td colSpan="6">查無資料</td>
                                    </tr>)
                                }
                            </tbody>
                        </table>
                    </div>
                    {/*換頁*/}<div className="col-12 py-3" style={{ backgroundColor: "#f2f2f2" }} >
                        <PageNav alLength={tableLength} eachPage={eachPage} />
                    </div>
                </div>

                <div className="footer sticky-bottom pt-5">
                    <div className="row text-center justify-content-center">
                        <div className="col-12">
                            <p>COPYRIGHT@<img src="images/EZwriting-txt.png" />　407臺中市西屯區長安路一段83號5樓<br />
                                電話：(04)2315-2500　客服信箱：service@ezwritingbar.com.tw</p>
                        </div>
                    </div>
                </div>
            </div>

            {/*<!--上傳檔案-->*/}
            <div className="modal fade" id="sys_upFile" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ "maxWidth": "650px" }}>
                    <div className="modal-content" style={{ margin: " 0 1rem" }}>
                        <div className="container">
                            <div className="row justify-content-center" style={{ padding: "4%" }}>
                                <div className="col-10">
                                    <table className="table border-bottom">
                                        <tbody>
                                            <tr>
                                                <td><input accept=".PDF" type="file" id="File" onChange={selectFile} ></input></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-center pb-2">
                                    <button type="button" className="mx-4 btn btn-primary" onClick={sendFile} >送出</button>
                                    <button type="button" className="mx-4 btn btn-outline-primary" onClick={hideModal} >關閉</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!--上傳檔案 END-->*/}

        </>
    );
}


export default finance;