import React from 'react';
import ReactDOM from 'react-dom';
import I18n from "./i18n"; //多國語言
import './index.css';
import reportWebVitals from './reportWebVitals';
import { HashRouter, Route, BrowserRouter, Switch } from "react-router-dom";
import Home from './pages/Home';
import Header from './components/partial/header';
import Finance from './pages/Finance';
import Assign from './pages/Assign';
import Review from './pages/Review';
import Score from './pages/Score';
import Account from './pages/Account';
import Plain from './pages/Plain';
import Promote from './pages/Promote';
//import { GoogleOAuthProvider } from '@react-oauth/google';
import Maintenance from './pages/Maintenance';

const header = (
    //<GoogleOAuthProvider clientId="775144696441-li2uhe3l2tijmvkclja9jo7l376j4h55.apps.googleusercontent.com">
    <Header />
    //</GoogleOAuthProvider>
);

ReactDOM.render(header, document.getElementById('header'));
const root = (
    //test
   
    <I18n>
        
        <BrowserRouter>          
            <Switch>
                {/*<Route exact path="/" component={Maintenance} />*/}
                {/*<Route exact path="/" component={Home} />*/}
                <Route exact path="/" component={Review} />
                <Route exact path="/Review" component={Review} />
                <Route exact path="/Finance" component={Finance} />
                <Route exact path="/Assign" component={Assign} />
                <Route exact path="/Score" component={Score} />
                <Route exact path="/Account" component={Account} />
                <Route exact path="/Plain" component={Plain} />
                <Route exact path="/Promote" component={Promote} />
            </Switch>
        </BrowserRouter >
    </I18n>
    
);

ReactDOM.render(root, document.getElementById('root'));;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
